<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :back="back" />
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Crear documento</h5>
        <p>Ingresa los datos de carga del documento nuevo</p>
      </div>
    </div>
    <div class="col-12 px-4 pb-4 mt-2">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Tipo de documento</label>
            <Multiselect
              id="legislation"
              v-model="tipoDocumento"
              :options="documento"
              track-by="type"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione un documento"
              label="type"
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} docuemento seleccionados</span
                ></template
              >
              <span slot="noOptions"> Aún no hay documentos. </span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>
            </Multiselect>
            <div class="error" v-if="submited && !$v.tipoDocumento.required">
              Este campo es requerido
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="name" class="form-label">Número</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="numero"
              placeholder="Ingrese un número"
              autocomplete="off"
            />
            <div class="error" v-if="submited && !$v.numero.required">
              Este campo es requerido
            </div>
            <div class="error" v-if="submited && !$v.numero.maxLength">
              Este campo debe tener menos de 10 caracteres
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="lastname" class="form-label">Serie</label>
            <input
              type="number"
              class="form-control"
              id="lastname"
              v-model="serie"
              placeholder="Ingrese una serie"
              autocomplete="off"
              @keypress="sancionNumber"
            />
            <div class="error" v-if="submited && !$v.serie.maxLength">
              Este campo debe tener menos de 10 caracteres
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Año sanción</label>
            <input
              type="text"
              class="form-control"
              id=""
              v-model="sancionDate"
              placeholder="Ingrese una año de sanción"
              autocomplete="off"
              maxlength="4"
              @keypress="sancionNumber"
            />
            <div class="error" v-if="submited && !$v.sancionDate.required">
              Este campo es requerido
            </div>
            <div class="error" v-if="submited && !$v.sancionDate.maxLength">
              Este campo debe tener menos de 4 caracteres
            </div>
            <div class="error" v-if="submited && !$v.sancionDate.minLength">
              Este campo debe tener menos de 4 caracteres
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Fecha sanción</label>
            <input
              type="date"
              class="form-control"
              id="email"
              v-model="fechaSancion"
              placeholder="Ingrese una fecha sanción"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Año promulgación</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="promulgacionDate"
              placeholder="Ingrese un año de promulgación"
              autocomplete="off"
              maxlength="4"
              @keypress="promulgacionNumber"
            />
            <div
              class="error"
              v-if="submited && !$v.promulgacionDate.maxLength"
            >
              Este campo debe tener menos de 4 caracteres
            </div>
            <div
              class="error"
              v-if="submited && !$v.promulgacionDate.minLength"
            >
              Este campo debe tener menos de 4 caracteres
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="name" class="form-label">Fecha de promulgación</label>
            <input
              type="date"
              class="form-control"
              id="name"
              v-model="fechaPromulgacion"
              placeholder="Ingrese una fecha de promulgación"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="lastname" class="form-label">Fecha boletín</label>
            <input
              type="date"
              class="form-control"
              id="lastname"
              v-model="fechaBoletin"
              placeholder="Ingrese una fecha de boletín"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Nro. boletín</label>
            <input
              type="number"
              class="form-control"
              id="email"
              v-model="numBoletin"
              placeholder="Ingrese una número de boletín"
              autocomplete="off"
            />
            <div class="error" v-if="submited && !$v.numBoletin.maxLength">
              Este campo debe tener menos de 10 caracteres
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Asunto</label>
            <input
              type="text"
              class="form-control"
              id="asunto"
              v-model="asunto"
              placeholder="Ingrese un asunto"
              autocomplete="off"
            />
            <div class="error" v-if="submited && !$v.asunto.required">
              Este campo es requerido
            </div>
            <div class="error" v-if="submited && !$v.asunto.maxLength">
              Este campo debe tener menos de 100 caracteres
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Aclaraciones</label>
            <input
              type="text"
              class="form-control"
              id="aclaracion"
              v-model="aclaracion"
              placeholder="Ingrese una acalración"
              autocomplete="off"
            />
            <div class="error" v-if="submited && !$v.aclaracion.maxLength">
              Este campo debe tener menos de 2000 caracteres
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Area</label>
            <Multiselect
              id="areas"
              v-model="tipoArea"
              :options="areas"
              track-by="name"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione un area"
              label="name"
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @input="selectArea()"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} areas seleccionadas</span
                ></template
              >
              <span slot="noOptions"> Aún no hay area.</span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>
            </Multiselect>
            <div class="error" v-if="submited && !$v.tipoArea.required">
              Este campo es requerido
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Palabras clave</label>
            <Multiselect
              id="keyword"
              v-model="palabraClave"
              :options="palabra"
              track-by=""
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione una palabra clave"
              label=""
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} areas seleccionadas</span
                ></template
              >
              <span slot="noOptions"> Aún no hay palabras claves.</span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>
            </Multiselect>

            <div class="text-end"> 
              <button class="mt-1 btn-addKeyword " v-b-modal.modalKeyword>
              Agregar una nueva palabra clave
            </button>
            <ModalKeyword :getArea='getArea' />
            </div>
            
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="search row justify-content-end mb-3">
            <div>
              <h5>Contenido</h5>
              <hr />
            </div>
            <div class="col">
              <form
                action="localhost:7020/api/document/save-file?fileName=6240840307efdc5604967b7d"
                method="post"
                enctype="multipart/form-data"
              >
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="file"
                    name="file"
                    @change="
                      () => {
                        size = 0;
                        updateFile();
                      }
                    "
                    autocomplete="off"
                  />
                  <label class="custom-file-label" for="file">{{
                    nameFile
                  }}</label>
                </div>
                <div class="error" v-if="submited && !$v.file.required">
                  Este campo es requerido
                </div>
              </form>
            </div>
          </div>

          <div class="col-12 mt-4 text-end">
            <button
              class="btn btn-create btn-sm"
              @click="createAndSaveDocument"
            >
              Crear documento
            </button>
            <ModalConfirmDocument />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import Nav from "../../public/nav/Nav.vue";
import ModalConfirmDocument from "./modal/ModalConfirmDocument.vue";
import ModalKeyword from "./modal/ModalKeyword.vue";
import Multiselect from "vue-multiselect";
import store from "../../../store/index";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      max: 4,
      back: true,
      submenu: "newDocument",
      submited: false,
      numero: "",
      serie: "",
      sancionDate: "",
      fechaSancion: "",
      promulgacionDate: "",
      tipoDocumento: "",
      documento: "",
      fechaPromulgacion: "",
      fechaBoletin: "",
      numBoletin: "",
      asunto: "",
      aclaracion: "",
      tipoArea: [],
      areas: [],
      palabra: [],
      palabraClave: [],
      file1: "",
      idFile: "",
      nameFile: "Importar un documento ",
      codeResponse: "",
      usuarios: [],
      usuariosProyecto: [],
      file: "",
    };
  },
  validations: {
    tipoDocumento: { required },
    numero: { required, maxLength: maxLength(10) },
    serie: { maxLength: maxLength(10) },
    sancionDate: { required, maxLength: maxLength(4), minLength: minLength(4) },
    fechaSancion: {},
    promulgacionDate: {
      maxLength: maxLength(4),
      minLength: minLength(4),
    },
    fechaPromulgacion: {},
    fechaBoletin: {},
    numBoletin: { maxLength: maxLength(10) },
    asunto: { required, maxLength: maxLength(100) },
    aclaracion: { maxLength: maxLength(2000) },
    tipoArea: { required },
    palabraClave: {},
    file: { required },
  },
  components: {
    Nav,
    ModalConfirmDocument,
    ModalKeyword,
    ToastificationContent,
    Multiselect,
  },
  mounted() {
    this.getLegisletion();
    this.getArea();
  },
  methods: {
    selectArea() {
      this.palabra = this.tipoArea.keywords;
      if (this.tipoArea.name) {
        this.palabraClave = "";
      }
    },
    updateFile() {
      this.nameFile = document.getElementById("file").files[0].name;
      this.file = document.getElementById("file").files[0].name;
    },
    async saveFile(id) {
      this.$store.commit("changeLoading", true);
      const formData = new FormData();
      formData.append("file", document.getElementById("file").files[0]);
      try {
        const response = await axios({
          method: "post",
          url: `${backendUrl}/document/save-file?fileName=${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: formData,
        });
        this.$store.commit("changeLoading", false);
        /* if(response.code == 2){
          console.log('response 2')
          
        } */
        
      } catch (error) {
        this.$store.commit("changeLoading", false);
      }
    },
    async getLegisletion() {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/legislation/get-legislations`,
        });
        if (response.status == 200) {
          this.documento = response.data.legislationsGetted;
        }
      } catch (error) {}
    },
    async getArea() {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/area/get-areas`,
        });
        if (response.status == 200) {
          this.areas = response.data.areasGetted;
        }
      } catch (error) {}
    },
    async createDocument() {
      this.submited = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        
        const data = {
          numero: this.numero,
          ...(this.serie && { serie: this.serie }),
          anioSancion: this.sancionDate,
          ...(this.fechaSancion && { fechaSancion: this.fechaSancion }),
          ...(this.promulgacionDate && {
            anioPromulgacion: this.promulgacionDate,
          }),
          tipoDocumento: this.tipoDocumento.type,
          ...(this.fechaPromulgacion && {
            fechaPromulgacion: this.fechaPromulgacion,
          }),
          ...(this.fechaBoletin && { fechaBoletin: this.fechaBoletin }),
          ...(this.numBoletin && { numeroBoletin: this.numBoletin }),
          asunto: this.asunto,
          area: this.tipoArea.name,
          ...(this.aclaracion && { aclaraciones: this.aclaracion }),
          ...(this.palabraClave && { palabrasClave: this.palabraClave }),
        };
        try {
          const response = await axios({
            method: "post",
            url: `${backendUrl}/document/create`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data,
          });
          this.codeResponse = response.data.code;
          this.idFile = response.data.documentInserted;
          this.submited = false;
          
          if (this.codeResponse === 1) {
            return this.$toast.error(response.data.message, {
              position: "top-right",
              timeout: 4000,
              hideProgressBar: true,
            });
          }
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });

          this.numero = "";
          this.serie = "";
          this.sancionDate = "";
          this.fechaSancion = "";
          this.promulgacionDate = "";
          this.tipoDocumento = "";
          this.fechaPromulgacion = "";
          this.fechaBoletin = "";
          this.numBoletin = "";
          this.asunto = "";
          this.aclaracion = "";
          this.tipoArea = "";
          this.palabraClave = "";
          this.nameFile = "Importar un documento ";
          if (this.codeResponse === 2) {
          }
        } catch (error) {
          /* this.$store.commit("changeLoading", false); */
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
        }
      }
    },
    async createAndSaveDocument() {
      
      try {
        await this.createDocument();
        if (this.codeResponse === 1) {
          return;
        }
        await this.saveFile(this.idFile);
      } catch (error) {
      }
    },
    valideKey(evt) {
      // code is the decimal ASCII representation of the pressed key.
      var code = evt.which ? evt.which : evt.keyCode;

      if (code == 8) {
        // backspace.
        return true;
      } else if (code >= 48 && code <= 57) {
        // is a number.
        return true;
      } else {
        // other keys.
        return false;
      }
    },
    sancionNumber(evt) {
      if (this.sancionDate.length >= 4) {
        evt.preventDefault();
        return;
      }
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },

    promulgacionNumber(evt) {
      if (this.promulgacionDate.length >= 4) {
        evt.preventDefault();
        return;
      }
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },

    /*  addKeyword(){
      console.log('eeeeeeee')
      this.$bvModal.hide("modal-1");
    } */
  },
};
</script>
<style scoped>
.form-control,
.form-select {
  font-size: 14px;
}
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.form-label {
  font-size: 14px;
  margin-bottom: 2px;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  background: #f44802;
  color: white;
  padding: 9px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}
.btn-addKeyword{
  background: transparent;
  font-weight: 600;
  border: none;
  font-size: 13px;
  color: #f44802;
}
/*etiqueta*/
.col-input {
  padding-right: 8px;
}
.col-add {
  margin-top: auto;
  max-width: 42px;
  padding: 0px;
  padding-right: 53px;
}

.col-add-import {
  margin-top: auto;
  max-width: 79px;
  padding: 0px;
  padding-right: 53px;
}

/*etiquetas*/
h5 {
  margin-bottom: 0;
  font-size: 15px;
}
hr {
  margin: 8px 0;
}

/*input-file*/
.custom-file {
  position: relative;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}
.custom-file-input {
  z-index: -5px;
  margin: 0;

  position: relative;
  width: 100%;
}
.custom-file-label {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;

  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  content: "Cargar";
  background-color: #858585;
  color: #fff;
  transition: 0.4s;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file-label::after:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
</style>