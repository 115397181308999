<template>
  <b-modal id="modalDeleteUser" centered>
    <div class="mb-3 text-center">
      <h5>¿Seguro quieres eliminar este usuario?</h5>

    </div>


     <template class="text-center" #modal-footer="{ cancel, hide }">
          <!-- Button with custom close trigger value -->
         <b-button class="btn-cancel"
          @click="cancel()">
          Cancelar
        </b-button>
          <b-button
            class="btn-logout"
            @click-self="hide('forget')"
            @click="funtionDelete"
          >
            Eliminar
          </b-button>
        </template>
  </b-modal>
</template>
<script>
export default {
    props: ["funtionDelete"],
  components: {
  },
};
</script>

<style scoped>
/deep/.close{
  display: none;
}
/deep/ .modal-header{
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body{
  padding-bottom: 15px;
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
}
/deep/ .modal-footer{
  border-top: none;
  padding-top: 0px;
  justify-content: center;
  padding-bottom: 35px;
}
.btn-cancel {
  background-color: #858585;
  color: #ffffff;
  border: none;
  transition: 0.4s;
  font-size: 14px;
}
.btn-cancel:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
.btn-logout {
  background-color: #F44802;
  color: #ffffff;
  border: none;
  transition: 0.4s;
  font-size: 14px;
}
.btn-logout:hover {
  background-color: #e6784c;
  color: #ffffff;
}

</style>

