<template>
  <div>
    <div class="row header">
      <div class="col-12" v-if="token == null">
        <div class="row" style="height: 100%">
          <div class="col align-self-center logo">
            <a href="https://digesto.jesusmaria.gov.ar/">
              <img src="../../assets/logo.png" alt="" />
            </a>
          </div>
          <div class="col text-end align-self-center">
            <div class="row">
              <div class="col text-profile"></div>
              <div class="col profile">
                <router-link to="/login">
                  <button class="btn btn-profile">
                    <i class="fa-solid fa-user"></i>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-else>
        <div class="row" style="height: 100%">
          <div class="col align-self-center">
            <img src="../../assets/logo.png" alt="" />
          </div>
          <div class="col text-end align-self-center">
            <div class="row">
              <div class="col text-profile">
                <h5>{{ user.name }} {{ user.lastname }}</h5>
                <p>{{ user.email }}</p>
              </div>
              <div class="col profile">
                <Profile :logout="logout" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row body">
        <div class="row">
          <RouterView />
        </div>
      </div>
    </div>
    <div class="row footer">
      <p>
        Municipalidad de Jesús María 03525-443776 / 7-443785 | San Martin 598,
        Jesús María | C.P. X5220 Jesús María - Argentina |
        comunicacionpublica@jesusmaria.gov.ar |
        <a href="http://www.jesusmaria.gov.ar/" target="_blank"
          >www.jesusmaria.gov.ar</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import Profile from "./Profile.vue";
export default {
  data() {
    return {
      token: "",
      user: {},
    };
  },
  components: {
    Profile,
  },
  mounted() {
    this.getDataUser();
  },
  methods: {
    async logout() {
      window.localStorage.clear();
      this.$router.push("/login");
    },
    getDataUser() {
      this.token = localStorage.getItem("token");
      this.user = jwt_decode(this.token);
    },
  },
};
</script>
<style scoped>
/*header*/
.header {
  background: #f44802;
  height: 70px;
}
.header img {
  height: 52px;
  padding-left: 20px;
}
.header .text-profile {
  padding-right: 0;
  color: white;
  margin: auto;
}
.header .text-profile h5 {
  margin-bottom: 0px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
}
.header .text-profile p {
  margin-bottom: 0px;
  font-size: 11px;
  color: #e3e3e3;
}
.header .profile {
  max-width: 60px;
  margin: auto;
}
.header .profile svg {
  font-size: 23px;
  color: white;
}
/*header*/
.header {
  background: #f44802;
  height: 70px;
}
.header img {
  height: 52px;
}
.header .text-profile {
  padding-right: 0;
  color: white;
  margin: auto;
}
.header .profile {
  max-width: 60px;
  margin: auto;
  margin-right: 15px;
}
.header .profile .btn-profile {
  border: none;
}
.header .profile .btn-profile i {
  font-size: 24px;
  color: white;
}
.header .profile .btn-profile i:hover {
  color: #e7e7e7;
  transition: 0.4s;
}

/*body*/
.body {
  min-height: calc(100vh - 70px - 30px);
  background: white;
  color: #707070;
}

/*footer*/
.footer {
  background: #f44802;
  min-height: 30px;
}
.footer p {
  color: white;
  margin: 0;
  font-size: 10px;
  margin: auto;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer p a {
  color: white;
  text-decoration: none;
  transition: 0.4s;
}
.footer p a:hover {
  color: rgb(223, 223, 223);
  text-decoration: none;
}
</style>
