<template>
  <div class="row bg-login justify-content-center">
    <div class="col card-login shadow-sm">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <img src="../../../assets/logo.png" alt="" />
        </div>
        <div class="col-12 text-center">
          <h5>MODIFICÁ TU CONTRASEÑA</h5>
        </div>
        <div class="col-10 pb-5">
          <form action="" autocomplete="off">
            <div class="form-group mt-5 container-pass">
              <label for="newPass">Nueva contraseña</label>
              <input
                class="form-control"
                id="newPass"
                name="newPass"
                v-model="newPasswordUser"
                placeholder="Ingresa tu nueva contraseña"
                autocomplete="off"
                :type="newPassFieldType"
              />
              <i
                class="icon-eye"
                :class="iconNewPass"
                @click="seeNewPass"
                id="icon"
              ></i>
              <div
                class="error-loggin"
                v-if="submited && !$v.newPasswordUser.required"
              >
                Este campo es requerido
              </div>
              <div
                class="error-loggin"
                v-if="submited && !$v.newPasswordUser.minLength"
              >
                La contraseña debe ser mas larga
              </div>
              <div
                class="error-loggin"
                v-if="submited && !$v.newPasswordUser.maxLength"
              >
                Este campo debe tener menos de 20 caracteres
              </div>
            </div>
            <div class="form-group mt-5 container-pass">
              <label for="repeatNewpass">Repetir contraseña</label>
              <input
                class="form-control"
                id="repeatNewpass"
                name="repeatNewpass"
                v-model="repeatPasswordUser"
                placeholder="Repetir tu nueva contraseña"
                autocomplete="off"
                :type="repeatPassFieldType"
                
              />
              <i
          class="icon-eye"
          :class="iconRepeatNewPass"
          @click="seeRepeatNewPass"
          id="icon"
        ></i>
        <div
          class="error"
          v-if="submited && !$v.repeatPasswordUser.sameAsPassword"
        >
          Las contraseñas deben coincidir
        </div>
            </div>
          </form>
          <div class="text-center">
            <button class="btn btn-white mt-5" @click="changePass">
              Modificar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jwt_decode from 'jwt-decode';
import axios from "axios";
import { backendUrl } from "./../../../config/index";
import store from "../../../store/index";
import {
  required,
  minLength,
  sameAs,
  maxLength,
} from "vuelidate/lib/validators";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";

export default {
  name: "Login",
  data() {
    return {
      submited: false,
      newPasswordUser: "",
      repeatPasswordUser: "",

      iconNewPass: "fas fa-eye",
      iconRepeatNewPass: "fas fa-eye",

      newPassFieldType: "password",
      repeatPassFieldType: "password",

      token:'',
      user: {},
      email: '',
    };
  },
  validations: {
    newPasswordUser: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    repeatPasswordUser: {
      sameAsPassword: sameAs("newPasswordUser"),
    },
  },
  components: {
    ToastificationContent,
  },
  mounted(){
  },
  methods: {
    seeNewPass() {
      this.newPassFieldType =
        this.newPassFieldType === "password" ? "text" : "password";
      this.iconNewPass =
        this.iconNewPass === "fas fa-eye" ? "fas fa-eye-slash" : "fas fa-eye";
    },
    seeRepeatNewPass() {
      this.repeatPassFieldType =
        this.repeatPassFieldType === "password" ? "text" : "password";
      this.iconRepeatNewPass =
        this.iconRepeatNewPass === "fas fa-eye"
          ? "fas fa-eye-slash"
          : "fas fa-eye";
    },
   
    async changePass() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("token");

      this.submited = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) { 
        this.$store.commit("changeLoading", true);
        const data = {
          password: this.newPasswordUser,
          token
        };
        try {
          const response = await axios({
            method: "put",
            url: `${backendUrl}/user/change-pass-forgot`,
            data,
          });
          this.$store.commit("changeLoading", false);
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
          this.$router.push("/login");
          this.newPasswordUser= "";
          this.repeatPasswordUser = "";
        } catch (error) {
          this.$store.commit("changeLoading", false);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });

          this.newPasswordUser= "";
          this.repeatPasswordUser = "";

        }
      }
  },
  }
};
</script>

<style scoped>
.bg-login {
  width: 100vw;
  height: 100vh;
  background: #e3e3e3;
  display: flex;
  align-items: center;
}
.card-login {
  background: #f44802;
  max-width: 400px;
  border-radius: 12px;
}
.card-login img {
  margin-top: 50px;
  width: 170px;
}
h5 {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: 40px 0;
}

.container-pass {
  position: relative;
}
.icon-eye {
  color: white;
  font-size: 15px;
  position: absolute;
  right: 11px;
  top: 31px;
  font-size: 14px;
  padding-top: 5px;
  cursor: pointer;
}
label {
  text-align: left;
  color: white;
  font-size: 15px;
}

input {
  font-size: 14px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #ffffffd3;
}
input:focus,
input:active,
input:visited {
  background-color: transparent !important;
  color: #ffffff;
  border-color: transparent !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f44802 inset;
  -webkit-text-fill-color: #ffffff;
}
::-webkit-input-placeholder {
  color: white;
} /* WebKit */
::-moz-placeholder {
  color: white;
} /* Firefox 19+ */
input::placeholder {
  color: rgb(221, 221, 221);
}
.restore-pass {
  display: block;
  text-align: end;
  font-size: 12px;
  text-decoration: none;
  color: #ffffff;
  margin-top: 5px;
  transition: 0.4s;
}
.restore-pass:hover {
  color: #d6d6d6;
}

.btn-white {
  border-radius: 8px;
  padding: 8px 25px;
  background-color: white;
  color: #f44802;
  /*margin-left: calc(50% - 69px);*/
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.4s;
}
.btn-white:hover {
  background-color: rgb(226, 226, 226);
  color: #f44802;
}
.p-back p {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: white;
}
a {
  text-decoration: none;
}
</style>