<template>
  <div class="col-12 px-4 mt-4 py-5">
    <div class="search row justify-content-end mt-5 pb-2">
      <div class="col-text col-lg col-md-6 mb-lg-0 mb-2">
        <h5>Documento de Transparencia</h5>
      </div>
      <div class="col-search col" v-if="documents.length > 0 && !spinnerTable">
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          placeholder="Buscar"
        ></b-form-input>
      </div>
    </div>
    <b-table
      v-if="documents.length > 0 && !spinnerTable"
      small
      :fields="fields"
      :items="documents"
      :per-page="perPage"
      :current-page="currentPage"
      responsive="md"
      :filter="filter"
    >
      <!-- A virtual composite column -->
      <template #cell(view)="data">
        <div class="text-end px-2" style="max-width: 60px; margin: auto">
          <button
            class="btn"
            @click="
              () => {
                details = data.item;
                seeDocumento(details);
              }
            "
          >
            <i class="fa-solid fa-eye"></i>
          </button>
        </div>
      </template>

      <!-- A virtual composite column -->
      <template #cell(delete)="data" v-if="this.user.isAdmin">
        <div class="text-end px-2" style="max-width: 60px; margin: auto">
          <button
            class="btn btn-sm btn-trash"
            @click="
              () => {
                id = data.item._id;
                
                $bvModal.show('modalDeleteSpecialDocument');
              }
            "
          >
            <i class="fa-solid fa-trash-can"></i>
          </button>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="documents.length > 0 && !spinnerTable"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      class="mb-5"
    ></b-pagination>
    <div v-if="documents.length == 0 && !spinnerTable">
      <h6 class="text-center my-4">Aún no hay documentos para visualizar</h6>
    </div>
    <div class="text-center my-5" v-if="spinnerTable">
      <b-spinner class="spinner-orange" label="Spinning"></b-spinner>
    </div>
    <ModalDeleteSpecialDocument :funtionDelete="funtionDelete" />
  </div>
</template>

<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import jwt_decode from "jwt-decode";
import ModalDeleteSpecialDocument from "./modal/Modal-deleteSpecialDocument.vue";
export default {
  data() {
    return {

      user: {},
      /*table*/
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "category",
          label: "Categoría",
          filterByFormatted: true,
          sortable: true,
          class: "text-mayus" 
        },
        {
          key: "title",
          label: "Título",
          filterByFormatted: true,
          sortable: true,
          class: "text-mayus" 
        },
        { key: "view", label: "" },
        { key: "delete", label: "" },
      ],
      filter: null,
      documents: [],
      spinnerTable: false,
      id: null,
    };
  },
  components: {
    
    ModalDeleteSpecialDocument,
  },
  mounted() {
    this.getDataUser();
    this.getDocuments();
  },
  methods: {
    getDataUser() {
      this.token = localStorage.getItem("token");
      this.user = jwt_decode(this.token);
    },

    async getDocuments() {
      this.spinnerTable = true;
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/special-document/getAll`,
        });
        if (response.status == 200) {
          this.documents = response.data.specialDocumentGetted;
        }
        this.spinnerTable = false;
      } catch (error) {
        this.spinnerTable = false;
      }
    },
    async downloadItem({ url, label, fileName }) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.setAttribute("download", fileName);
      link.click();
      URL.revokeObjectURL(link.href);
    },
    seeDocumento(details) {
      this.$store.commit("saveDocumentSelect", details);
      this.$router.push({ path: "/special-document-detail" });
    },
    funtionDelete() {
      this.deleteArea(this.id);
    },
    async deleteArea(id) {
      this.$store.commit("changeLoading", true);
      try {
        const response = await axios({
          method: "delete",
          url: `${backendUrl}/special-document/delete/${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.getDocuments();
        this.$store.commit("changeLoading", false);
        this.$toast.success(response.data.message, {
          position: "top-right",
          timeout: 2000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteSpecialDocument");
      } catch (error) {
        this.$store.commit("changeLoading", false);
        this.$toast.error(error.response.data.message, {
          position: "top-right",
          timeout: 4000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteSpecialDocument");
      }
    },
  },
  computed: {
    rows() {
      return this.documents.length;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
};
</script>

<style scoped>

/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}

.th[aria-colindex="6"]{
max-width: 60px;
}
</style>