<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :backSearch="backSearch" />
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Documento</h5>
        <p>Se muestran también los datos de carga del mismo</p>
        <span v-for="palabra in detalle.palabrasClave" :key="palabra">
          <span class="badge rounded-pill bg-orange">{{ palabra }}</span>
        </span>
        <!-- <span class="badge rounded-pill bg-orange">nombre</span> -->
      </div>
    </div>
    <div class="col-12 px-4 pb-4 mt-2">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="name" class="form-label">Número</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="detalle.numero"
              disabled
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="lastname" class="form-label">Serie</label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              v-model="detalle.serie"
              disabled
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Año sanción</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="detalle.anioSancion"
              disabled
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Fecha sanción</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="detalle.fechaSancion"
              disabled
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="name" class="form-label">Nro. expediente</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="detalle.numeroExpediente"
              disabled
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="lastname" class="form-label">Nro. decreto</label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              v-model="detalle.numeroDecreto"
              disabled
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Año promulgación</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="detalle.anioPromulgacion"
              disabled
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Tipo Documento</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="detalle.tipoDocumento"
              disabled
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="name" class="form-label">Fecha de promulgación</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="detalle.fechaPromulgacion"
              disabled
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="lastname" class="form-label">Fecha boletín</label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              v-model="detalle.fechaBoletin"
              disabled
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Nro. boletín</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="detalle.numeroBoletin"
              disabled
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Asunto</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="detalle.asunto"
              disabled
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Aclaraciones</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="detalle.aclaraciones"
              disabled
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-12 mt-4">
          <div class="search row justify-content-end mb-3">
            <div>
              <h5>Contenido</h5>
              <hr />
            </div>
            <div class="col-md-10 col-12 m-auto">
              <p class="nameDocument">{{ detalle.fileName }}</p>
            </div>
            <div class="col-md-2 col-12 text-end d-md-block d-none">
              <a
                class="btn btn-document btn-sm"
                :href="'http://' + detalle.savePath"
                target="_black"
                v-if="detalle.isPDF"
                >Ver archivo</a
              >

              <a
                class="btn btn-document btn-sm"
                href="#"
                @click.prevent="
                  downloadItem({
                    url: 'https://' + detalle.savePath,
                    label: 'example',
                    fileName: detalle.fileName,
                  })
                "
                v-if="!detalle.isPDF"
              >
                Descargar archivo
              </a>
              <!-- <button :href="detalle.savePath">Ver archivo</button> -->
            </div>
            <div class="col-md-2 col-12 text-end d-md-none d-block">
              <a
                class="btn btn-document btn-sm"
                :href="'http://' + detalle.savePath"
                @click.prevent="
                  downloadItem({
                    url: 'https://' + detalle.savePath,
                    label: 'example',
                    fileName: detalle.fileName,
                  })
                "
                v-if="detalle.isPDF"
                >Ver archivo</a
              >
              <a
                class="btn btn-document btn-sm"
                href="#"
                @click.prevent="
                  downloadItem({
                    url: 'https://' + detalle.savePath,
                    label: 'example',
                    fileName: detalle.fileName,
                  })
                "
                v-if="!detalle.isPDF"
              >
                Descargar archivo
              </a>
              <!-- <button :href="detalle.savePath">Ver archivo</button> -->
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import Nav from "../../public/nav/Nav.vue";
import store from "../../../store/index";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";
export default {
  props: ["id"],
  data() {
    return {
      backSearch: true,
      detalle: {},
      submenu: "DocumentDetail",
      submited: false,
      numero: "",
      serie: "",
      sancionDate: "",
      fechaSancion: "",
      numExpediente: "",
      numDecreto: "",
      promulgacionDate: "",
      tipoDocumento: "",
      fechaPromulgacion: "",
      fechaBoletin: "",
      numBoletin: "",
      asunto: "",
      aclaracion: "",
      etiquetas: "",
    };
  },

  components: {
    Nav,
    ToastificationContent,
  },
  mounted() {
    this.detalle = this.$store.state.appConfig.documentSelect;
    console.log('estes el detalle del doc',this.detalle)
  },
  methods: {
    async downloadItem({ url, label, fileName }) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.setAttribute("download", fileName);
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>
<style scoped>
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.titles .bg-orange {
  font-size: 11px;
  background-color: #f44802;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  margin-right: 5px;
}
.form-label {
  font-size: 14px;
  margin-bottom: 2px;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  color: white;
  padding: 9px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}
.form-control{
  text-transform: uppercase !important;
}
/*etiqueta*/
.col-input {
  padding-right: 8px;
}
.col-add {
  margin-top: auto;
  max-width: 42px;
  padding: 0px;
  padding-right: 53px;
}
.btn-document {
  background: #f44802;
  color: #ffffff;
  transition: 0.4s;
  font-size: 9px;
  margin-right: 15px;
  text-transform: uppercase;
}
.btn-document:hover {
  background-color: #e6784c;
  color: #ffffff;
}
.col-add-import {
  margin-top: auto;
  max-width: 79px;
  padding: 0px;
  padding-right: 53px;
}

/*etiquetas*/

h5 {
  margin-bottom: 0;
  font-size: 15px;
}
hr {
  margin: 8px 0;
}
.nameDocument {
  font-size: 12px;
  padding-left: 15px;
  margin-bottom: 0;
}
</style>