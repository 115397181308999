<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :back="back" />
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Crear documento</h5>
        <p>Ingresa los datos de carga del documento nuevo</p>
      </div>
    </div>
    <div class="col-12 px-4 pb-4 mt-2">
      <div class="row">
        <div class="col-input col-12 mb-3">
          <label for="name" class="form-label">Título</label>
          <input
            class="form-control"
            type="text"
            placeholder="Agregar un titulo al archivo"
            v-model="title"
            autocomplete="off"
          />
          <div class="error" v-if="submited && !$v.title.required">
            Este campo es requerido
          </div>
          <div class="error" v-if="submited && !$v.title.maxLength">
            Este campo debe tener menos de 50 caracteres
          </div>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <label for="" class="form-label">Categoría</label>
            <Multiselect
              id="keyword"
              v-model="typeCategory"
              :options="categorys"
              track-by="name"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione una categoría"
              label="name"
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              class="category"
            >
              <span slot="noOptions"> Aún no hay tipo de categoría.</span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>
            </Multiselect>
            <div class="error" v-if="submited && !$v.typeCategory.required">
              Este campo es requerido
            </div>
          </div>
        </div>

        <div
          class="col-md-6 col-12"
          v-for="(category, index) in typeCategory.fields"
          :key="index"
        >
          <div class="mb-3">
            <label for="name" class="form-label">{{
              category.nameField
            }}</label>
            <input
            v-if="category.type != 'month'"
              v-model="category.value"
              :type="category.type"
              class="form-control"
              id="name"
              placeholder="Ingrese un dato"
              autocomplete="off"
            />

            <Multiselect
              v-if="category.type === 'month'"
              id="month"
              v-model="category.value"
              :options="month"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione un mes"
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
            >
              <span slot="noOptions"> Aún no hay tipo de categoría.</span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>
            </Multiselect>
            <!--  <div class="error" v-if="submited && !$v.numero.required">
              Este campo es requerido
            </div>
            <div class="error" v-if="submited && !$v.numero.maxLength">
              Este campo debe tener menos de 10 caracteres
            </div> -->
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="search row justify-content-end mb-3">
            <div>
              <h5>Contenido</h5>
              <hr />
            </div>
            <div class="col">
              <form
                action="localhost:7020/api/document/save-file?fileName=6240840307efdc5604967b7d"
                method="post"
                enctype="multipart/form-data"
              >
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="file"
                    name="file"
                    @change="
                      () => {
                        size = 0;
                        updateFile();
                      }
                    "
                    autocomplete="off"
                  />
                  <label class="custom-file-label" for="file">{{
                    nameFile
                  }}</label>
                </div>
                <div class="error" v-if="submited && !$v.file.required">
                  Este campo es requerido
                </div>
              </form>
            </div>
          </div>

          <div class="col-12 mt-4 text-end">
            <button
              class="btn btn-create btn-sm"
              @click="createAndSaveDocument"
            >
              Crear documento
            </button>
          </div>
        </div>
        <div class="col-12 my-2">
          <!--   <button class="btn btn-orange btn-outline-dark" @click="createDocument">Agregar campo</button>
 -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import Nav from "../../public/nav/Nav.vue";
import Multiselect from "vue-multiselect";
import store from "../../../store/index";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      submited: false,
      max: 4,
      back: true,
      submenu: "newSpecialDocument",
      typeCategory: "",
      categorys: [],
      title: "",
      nameFile: "Importar un documento ",
      /*docuemnt*/
      codeResponse: "",
      idFile: "",
      file: "",
      month:[
        'Enero', 'Febrero', 'Marzo','Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ]
    };
  },
  validations: {
    title: { required, maxLength: maxLength(50) },
    typeCategory: { required },
    file: { required },
  },
  components: {
    Nav,
    ToastificationContent,
    Multiselect,
  },
  mounted() {
    this.getSpecialcCategory();
  },
  methods: {
    updateFile() {
      this.nameFile = document.getElementById("file").files[0].name;
      this.file = document.getElementById("file").files[0].name;
    },
    async saveFile(id) {
      const formData = new FormData();
      formData.append("file", document.getElementById("file").files[0]);
      try {
        const response = await axios({
          method: "post",
          url: `${backendUrl}/special-document/save-file?fileName=${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: formData,
        });
      } catch (error) {
      }
    },
    async getSpecialcCategory() {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/special-category/get`,
        });
        if (response.status == 200) {
          this.categorys = response.data.specialCategoriesGetted;
        }
      } catch (error) {
      }
    },
    async createDocument() {
      this.submited = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        this.$store.commit("changeLoading", true);
        const fields = this.typeCategory.fields.map((field) => {
          if (!field.value) {
            return;
          }
          return {
            name: field.nameField,
            type: field.type,
            value: field.value,
          };
        });
        const data = {
          title: this.title,
          category: this.typeCategory.name,
          fields,
        };
        try {
          const response = await axios({
            method: "post",
            url: `${backendUrl}/special-document/create`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data,
          });
          this.typeCategory.fields.forEach(field => field.value = "");
          this.codeResponse = response.data.code;
          this.idFile = response.data.documentInserted;
          this.$store.commit("changeLoading", false);
          if (this.codeResponse === 1) {
            return this.$toast.error(response.data.message, {
              position: "top-right",
              timeout: 4000,
              hideProgressBar: true,
            });
          }
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
          this.title = "";
          this.typeCategory = "";
          this.nameFile = "Importar un documento";
          this.submited = false;
        } catch (error) {
          this.$store.commit("changeLoading", false);
           this.submited = false;
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
          if(error.response.status === 400){
          this.$toast.error('Los campos son invalidos. Corrobore los datos enviados', {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
        }
        }
      }
    },
    async createAndSaveDocument() {
      try {
        await this.createDocument();
        if (this.codeResponse === 1) {
          return;
        }
        await this.saveFile(this.idFile);
      } catch (error) {
      }
    },
  },
};
</script>
<style scoped>
.multiselect__option{
  text-transform: capitalize !important;
}
.form-control,
.form-select {
  font-size: 14px;
}
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.form-label {
  font-size: 14px;
  margin-bottom: 2px;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  background: #f44802;
  color: white;
  padding: 9px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}

/*etiqueta*/
.col-input {
  padding-right: 8px;
}
.col-add {
  margin-top: auto;
  max-width: 42px;
  padding: 0px;
  padding-right: 53px;
}

.col-add-import {
  margin-top: auto;
  max-width: 79px;
  padding: 0px;
  padding-right: 53px;
}

/*etiquetas*/
h5 {
  margin-bottom: 0;
  font-size: 15px;
}
hr {
  margin: 8px 0;
}

/*input-file*/
.custom-file {
  position: relative;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}
.custom-file-input {
  z-index: -5px;
  margin: 0;

  position: relative;
  width: 100%;
}
.custom-file-label {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;

  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  content: "Cargar";
  background-color: #858585;
  color: #fff;
  transition: 0.4s;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file-label::after:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
</style>