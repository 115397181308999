<template>
  <div class="row">
    <div class="col-12">
      <div class="nav">
        <span class="inicio">Inicio</span>

   
        <span v-if="submenu" class="bar px-1"> \ </span>
        <span v-if="submenu == 'newUser'"> Crear usuario</span>
        <span v-if="submenu == 'newData'"> Carga de datos</span>
        <span v-if="submenu == 'newCategory'"> Crear Categoría</span>
        <span v-if="submenu == 'newDocument'"> Nuevo documento</span>
        <span v-if="submenu == 'newSpecialDocument'"> Nuevo documento de trasnparencia</span>
        
        <span v-if="submenu == 'homeSearch'"> Buscador</span>
        <span v-if="submenu == 'searchDocument'"> Buscar documento</span>
        <span v-if="submenu == 'searchSpecialDocument'"> Buscar documento de transparencia</span>
        

        

<!-- menu de buscar docuemnto -->
        <!-- <router-link to="/">
          <span v-if="submenu == 'DocumentDetail'"> Buscar documento</span>
        </router-link> -->
        <span v-if="submenu == 'DocumentDetail'" class="px-1">
          Detalle del documento</span
        >
      </div>
    </div>
    <div class="line"></div>
    <span class="text-end text-back" v-if="back == true && this.user.email" @click="$router.go(-1)"> < Volver atrás</span>
    <span class="text-end text-back" v-if="backSearch" @click="$router.go(-1)"> < Volver atrás</span>
  </div>
</template>
<script>
import jwt_decode from "jwt-decode";
export default {
  data() {
    return {
      user: {},
    };
  },
  props: ["submenu", "back", "backSearch"],
  components: {},
  mounted() {
    this.getDataUser();
  },
  methods:{
     getDataUser() {
      this.token = localStorage.getItem("token");
      this.user = jwt_decode(this.token);
    },
  }
};
</script>
<style scoped>
a{
  text-decoration: none;
  color: #707070;
}
a:hover{
  text-decoration: none;
  color: #999999;
}
.nav .inicio {
  color: #f44802;
}
.line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #f44802;
  margin: 1rem 0 1rem 0;
}
.bar {
  color: #f44802;
}
a {
  text-decoration: none;
}

.text-back{
  font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}
.text-back:hover{
  color:#999999
}
</style>