import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'
import changePass from '../views/shared/changePass/ChangePass.vue'
import emailChangePass from '../views/shared/changePass/EmailChangePass.vue'
import Layout from '../views/layout/Layout.vue'
import Home from '../views/shared/home/Home.vue'
import NewUser from '../views/admin/newUser/NewUser.vue'
import NewData from '../views/admin/newData/NewData.vue'
import NewDocument from '../views/shared/newDocument/NewDocument.vue'
import HomeSearch from '../views/public/home/HomeSearch.vue'
import Search from '../views/public/search/Search.vue'
import SearchSpecialDocument from '../views/public/searchSpecialDocument/SearchSpecialDocument.vue'
import DocumentDetail from '../views/public/search/DocumentDetail.vue'
import SpecialDocumentDetail from '../views/public/searchSpecialDocument/SpecialDocumentDetail.vue'
import NewCategory from '../views/admin/newCategory/NewCategory.vue'
import NewSpecialDocument from '../views/shared/newSpecialDocument/NewSpecialDocument.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/change-pass',
    name: 'changePass',
    component: changePass,
  },
  {
    path: '/email-change-pass',
    name: 'emailChangePass',
    component: emailChangePass,
  },
  {
    path: '/',
    name: 'about',
    component: Layout,
    children: [
      {
        name: 'homeSearch',
        path: '/',
        component: HomeSearch,
      },
      {
        name: 'search',
        path: '/search',
        component: Search,
      },
      {
        name: 'searchSpecialDocument',
        path: '/search-special-document',
        component: SearchSpecialDocument,
      },

      
      {
        name: 'documentDetail',
        path: '/document-detail',
        props: true,
        component: DocumentDetail,
      },
      {
        name: 'specialDocumentDetail',
        path: '/special-document-detail',
        props: true,
        component: SpecialDocumentDetail,
      },

      
      {
        name: 'home',
        path: '/home',
        component: Home,
        meta: {
          authenticated: true,
        },
      },
      {
        name: 'newUser',
        path: '/new-user',
        component: NewUser,
        meta: {
          authenticated: true,
        },
      },
      {
        name: 'newData',
        path: '/new-data',
        component: NewData,
        meta: {
          authenticated: true,
        },
      },
      
      {
        name: 'newDocument',
        path: '/new-document',
        component: NewDocument,
        meta: {
          authenticated: true,
        },
      },{
        name: 'newCategory',
        path: '/new-category',
        component: NewCategory,
        meta: {
          authenticated: true,
        },
      },
      {
        name: 'newSpecialDocument',
        path: '/new-special-document',
        component: NewSpecialDocument,
        meta: {
          authenticated: true,
        },
      },
      

    ],
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
