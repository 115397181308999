<template>
  <b-modal id="confirmDocument" centered>
    <div class="mb-3 text-center">
      <h5>¿Seguro quieres guardar este documento?</h5>

    </div>


     <template class="text-center" #modal-footer="{ cancel, hide }">
          <!-- Button with custom close trigger value -->
         <b-button class="btn-cancel"
          @click="cancel()">
          Cancelar
        </b-button>
          <b-button
            class="btn-orange"
            @click-self="hide('forget')"
            @click="save"
          >
            Guardar
          </b-button>
        </template>
  </b-modal>
</template>
<script>

export default {
  props: ["logout"],
  components: {
  },
};
</script>

<style scoped>
/deep/.close{
  display: none;
}
/deep/ .modal-header{
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body{
  padding-bottom: 15px;
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
}
/deep/ .modal-footer{
  border-top: none;
  padding-top: 0px;
  justify-content: center;
  padding-bottom: 35px;
}
.btn-cancel {
  background-color: #858585;
  color: #ffffff;
  border: none;
  transition: 0.4s;
  font-size: 14px;
}
.btn-cancel:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
.btn-orange {
  background-color: #F44802;
  color: #ffffff;
  border: none;
  transition: 0.4s;
  font-size: 14px;
}
.btn-orange:hover {
  background-color: #e6784c;
  color: #ffffff;
}

</style>

<template>
<div class="modal fade" id="modalConfirmDocument"  aria-labelledby="modalChangePass" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalChangePass"></h5>
      </div>
      <div class="modal-body">
        <h5 class="text-center mb-4">¿Seguro quieres guardar este documento?</h5>
      </div>
      <div class="modal-footer m-auto">
        <button type="button" class="btn btn-cancel shadow-sm">Cancelar</button>
        <button type="button" class="btn btn-modify shadow-sm">Guardar</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style scoped>
.btn-close{
    display: none;
}
.modal-title{
    color: #646464;
    font-size: 17px;
}
.modal-header{
    border-bottom: none;
    padding-top: 25px;
    padding-left: 35px;
    padding-right: 35px;
}
.modal-body{
    text-align: left;
    padding-bottom: 8px;
    padding-top: 5px;
    padding-left: 35px;
    padding-right: 35px;
}
.modal-body .form-label{
    font-size: 14px;
    margin-bottom: 2px;
}
.modal-footer{
     border-top: none;
     padding-top: 0;
     padding-bottom: 25px;
     padding-left: 35px;
    padding-right: 35px;
}
.btn-cancel {
  background-color: #858585;
  color: #ffffff;
  transition: 0.4s;
}
.btn-cancel:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
.btn-modify {
  background-color: #F44802;
  color: #ffffff;
  transition: 0.4s;
}
.btn-modify:hover {
  background-color: #e6784c;
  color: #ffffff;
}


</style>