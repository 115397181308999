<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :backSearch="backSearch" />
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Documento</h5>
        <p>Se muestran también los datos de carga del mismo</p>
        <span v-for="palabra in detalle.palabrasClave" :key="palabra">
          <span class="badge rounded-pill bg-orange">{{ palabra }}</span>
        </span>
        <!-- <span class="badge rounded-pill bg-orange">nombre</span> -->
      </div>
    </div>
    <div class="col-12 px-4 pb-4 mt-2">
      <div class="row">
         <div
          class="col-md-6 col-12"
          v-for="(detalle, index) in Object.entries(detalle.fields)"
          :key="index"
        >
          <div class="mb-3">
            <label for="name" class="form-label">{{
            detalle[0]
            }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Ingrese un dato"
              autocomplete="off"
              :value="detalle[1]"
              disabled
            />
            <!--  <div class="error" v-if="submited && !$v.numero.required">
              Este campo es requerido
            </div>
            <div class="error" v-if="submited && !$v.numero.maxLength">
              Este campo debe tener menos de 10 caracteres
            </div> -->
          </div>
        </div>

        <div class="col-12 mt-4">
          <div class="search row justify-content-end mb-3">
            <div>
              <h5>Contenido</h5>
              <hr />
            </div>
            <div class="col-md-10 col-12 m-auto">
              <p class="nameDocument">{{ detalle.fileName }}</p>
            </div>
            <div class="col-md-2 col-12 text-end d-md-block d-none">
              <a
                class="btn btn-document btn-sm"
                :href="'http://' + detalle.savePath"
                target="_black"
                v-if="detalle.isPDF"
                >Ver archivo</a
              >

              <a
                class="btn btn-document btn-sm"
                @click.prevent="
                  downloadItem({
                    url: 'https://' + detalle.savePath,
                    label: 'example',
                    fileName: detalle.fileName,
                  })
                "
                v-if="!detalle.isPDF"
              >
                Descargar archivo
              </a>
              <!-- <button :href="detalle.savePath">Ver archivo</button> -->
            </div>
            <div class="col-md-2 col-12 text-end d-md-none d-block">
              <a
                class="btn btn-document btn-sm"
                :href="'http://' + detalle.savePath"
                @click.prevent="
                  downloadItem({
                    url: 'https://' + detalle.savePath,
                    label: 'example',
                    fileName: detalle.fileName,
                  })
                "
                v-if="detalle.isPDF"
                >Descargar archivo</a
              >

              <a
                class="btn btn-document btn-sm"
                @click.prevent="
                  downloadItem({
                    url: 'https://' + detalle.savePath,
                    label: 'example',
                    fileName: detalle.fileName,
                  })
                "
                v-if="!detalle.isPDF"
              >
                Descargar archivo
              </a>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import Nav from "../nav/Nav.vue";
import store from "../../../store/index";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";
export default {
  props: ["id"],
  data() {
    return {
      backSearch: true,
      detalle: {},
      submenu: "DocumentDetail",
      submited: false,
      numero: "",
      serie: "",
      sancionDate: "",
      fechaSancion: "",
      numExpediente: "",
      numDecreto: "",
      promulgacionDate: "",
      tipoLegislación: "",
      fechaPromulgacion: "",
      fechaBoletin: "",
      numBoletin: "",
      asunto: "",
      aclaracion: "",
      etiquetas: "",
    };
  },

  components: {
    Nav,
    ToastificationContent,
  },
  mounted() {
    this.detalle = this.$store.state.appConfig.documentSelect;
  },
  methods: {
    async downloadItem({ url, label, fileName }) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.setAttribute("download", fileName);
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>
<style scoped>
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.titles .bg-orange {
  font-size: 11px;
  background-color: #f44802;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  margin-right: 5px;
}
.form-control{
  text-transform: uppercase !important;
}
.form-label {
  font-size: 14px;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  color: white;
  padding: 9px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}

/*etiqueta*/
.col-input {
  padding-right: 8px;
}
.col-add {
  margin-top: auto;
  max-width: 42px;
  padding: 0px;
  padding-right: 53px;
}
.btn-document {
  background: #f44802;
  color: #ffffff;
  transition: 0.4s;
  font-size: 9px;
  margin-right: 15px;
  text-transform: uppercase;
}
.btn-document:hover {
  background-color: #e6784c;
  color: #ffffff;
}
.col-add-import {
  margin-top: auto;
  max-width: 79px;
  padding: 0px;
  padding-right: 53px;
}

/*etiquetas*/

h5 {
  margin-bottom: 0;
  font-size: 15px;
}
hr {
  margin: 8px 0;
}
.nameDocument {
  font-size: 12px;
  padding-left: 15px;
  margin-bottom: 0;
}
</style>