<template>
  <div>
    <div class="col-12 mt-5">
      <Nav />
    </div>

    <div class="col-12 px-4 pb-5">
      <div class="botones-admins row">

        <div class="col-lg col-md-6 col-sm-12 mb-lg-3 mb-3 col-btn" v-if="this.user.isAdmin">
          <router-link to="/new-user" >
            <div class="button-home">
              <div class="row">
                <div class="col col-svg-max">
                  <i class="fa-solid fa-user-plus"></i>
                </div>
                <div class="col m-auto">
                  <p>Crear</p>
                  <p class="text-two">Nuevo usuario</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="col-lg col-md-6 col-sm-12 mb-lg-3 mb-3 col-btn" v-if="this.user.isAdmin">
          <router-link to="/new-data" >
            <div class="button-home">
              <div class="row">
                <div class="col col-svg">
                  <i class="fas fa-italic"></i>
                </div>
                <div class="col m-auto">
                  <p>Crear</p>
                  <p class="text-two">Nuevo dato</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="col-lg col-md-6 col-sm-12 mb-lg-3 mb-3 col-btn" v-if="this.user.isAdmin">
          <router-link to="/new-category" >
            <div class="button-home">
              <div class="row">
                <div class="col col-svg">
                  <i class="fas fa-italic"></i>
                </div>
                <div class="col m-auto">
                  <p>Crear Nueva</p>
                  <p class="text-two"> categoría</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="col-lg col-md-6 col-sm-12 mb-lg-3 mb-3 col-btn">
          <router-link to="/new-document">
            <div class="button-home">
              <div class="row">
                <div class="col col-svg">
                  <i class="fa-solid fa-file"></i>
                </div>
                <div class="col m-auto">
                  <p>Crear</p>
                  <p class="text-two">Nuevo documento</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
         <div class="col-lg col-md-6 col-sm-12 mb-lg-3 mb-3 col-btn">
          <router-link to="/new-special-document">
            <div class="button-home">
              <div class="row">
                <div class="col col-svg">
                  <i class="fa-solid fa-file"></i>
                </div>
                <div class="col m-auto">
                  <p>Crear Nuevo</p>
                  <p class="text-two"> doc. de transparencia</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg col-md-6 col-sm-12 mb-lg-3 mb-3 col-btn">
          <router-link to="/">
            <div class="button-home">
              <div class="row">
                <div class="col col-svg">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="col m-auto">
                  <p>Buscar </p>
                  <p class="text-two">documento</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>

      </div>
    </div>

    <TableDigesto/>
    <TableSpecialDocument/>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Nav from "../../public/nav/Nav.vue";
/* import ModalDeleteDocument from './modal/Modal-deleteDocument.vue' */
import TableDigesto from './TableDigesto.vue'
import TableSpecialDocument from './TableSpecialDocument.vue'
export default {
 /*  props: ["user"], */
  data() {
    return {
      back: true,
      submenu: "home",
      user: {},
       /*table*/
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "tipoDocumento", label: "Tipo", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "numero", label: "Número", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "anioSancion", label: "Año", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "asunto", label: "Asunto", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "view", label: "" },
        { key: "delete", label: "" },
      ],
      filter: null,
      documents: [],
      spinnerTable: false,
      id:0,
    };
  },
  components: {
    Nav,
    /* ModalDeleteDocument, */
    TableDigesto,
    TableSpecialDocument
  },
  mounted() {
    this.getDataUser();
    this.getDocuments();
  },
  methods: {
    getDataUser() {
      this.token = localStorage.getItem("token");
      this.user = jwt_decode(this.token);
    },

    async getDocuments() {
      this.spinnerTable = true
      try {
        const response = await axios({
          method: "post",
          url: `${backendUrl}/document/get`,
        });
        if (response.status == 200) {
          this.documents = response.data.documentGetted;
          console.log('dddddddddddd',this.documents)
        }
        this.spinnerTable = false
      } catch (error) {
        this.spinnerTable = false
      }
    },
    async downloadItem({ url, label, fileName }) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.setAttribute('download', fileName)
      link.click();
      URL.revokeObjectURL(link.href);
    },
    seeDocumento(details) {
      this.$store.commit("saveDocumentSelect", details);
      this.$router.push({ path: "/document-detail" });
    },
    funtionDelete() {
      this.deleteArea(this.id);
    },
    async deleteArea(id) {
      this.$store.commit("changeLoading", true);
      try {
        const response = await axios({
          method: "delete",
          url: `${backendUrl}/document/delete/${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.getDocuments();
        this.$store.commit("changeLoading", false);
        this.$toast.success(response.data.message, {
          position: "top-right",
          timeout: 2000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteDocument");
      } catch (error) {
        this.$store.commit("changeLoading", false);
        this.$toast.error(error.response.data.message, {
          position: "top-right",
          timeout: 4000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteDocument");
      }
    },
  },
  computed: {
    rows() {
      return this.documents.length;
    },
    sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
  },
};
</script>
<style scoped>
.spinner-orange{
  color: #f44802;
}
.col-btn {
  max-width: 270px;
  min-width: 270px;
}
.button-home {
  background: #f44802;
  color: white;
  padding: 25px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
}
.button-home:hover {
  background-color: #e6784c;
}
.button-home .col-svg {
  max-width: 40px;
  margin: auto;
}
.button-home .col-svg-max {
  max-width: 45px;
  margin: auto;
}
.button-home i {
  font-size: 30px;
}
.button-home p {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}
.button-home .text-two {
  margin-top: -5px;
}
a{
 text-decoration: none;
}

/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}

.th[aria-colindex="6"]{
max-width: 60px;
}
</style>