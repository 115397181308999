<template>
  <div>
    <b-modal id="modalChangePassUser" centered>
      <template #modal-header>
        <h5 class="modal-title" id="modalChangePass">
          {{ nameUser }} {{ lastnameUser }}
        </h5>
      </template>

      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          id="emailUser"
          v-model="emailUser"
          disabled
          autocomplete="off"
        />
      </div>
    <!--   <div class="mb-3 container-pass">
        <label for="currentPasswordUser" class="form-label"
          >Contraseña actual</label
        >
        <input
          type="text"
          class="form-control"
          id="currentPasswordUser"
          v-model="currentPasswordUser"
          :type="passFieldType"
          autocomplete="off"
        />
        <i class="icon-eye" :class="iconPass" @click="seePass" id="icon"></i>
        <div class="error" v-if="submited && !$v.currentPasswordUser.required">
          Este campo es requerido
        </div>
        <div class="error" v-if="submited && !$v.currentPasswordUser.minLength">
          La contraseña debe ser mas larga
        </div>
      </div>
      <div class="mb-3 container-pass">
        <label for="newPasswordUser" class="form-label">Nueva contraseña</label>
        <input
          type="text"
          class="form-control"
          id="newPasswordUser"
          v-model="newPasswordUser"
          :type="newPassFieldType"
          autocomplete="off"
        />
        <i
          class="icon-eye"
          :class="iconNewPass"
          @click="seeNewPass"
          id="icon"
        ></i>
        <div class="error" v-if="submited && !$v.newPasswordUser.required">
          Este campo es requerido
        </div>
        <div class="error" v-if="submited && !$v.newPasswordUser.minLength">
          La contraseña debe ser mas larga
        </div>
         <div class="error" v-if="submited && !$v.newPasswordUser.maxLength">
          Este campo debe tener menos de 20 caracteres
        </div>
      </div>
      <div class="container-pass">
        <label for="repeatNewPassword" class="form-label"
          >Repetir nueva contraseña</label
        >
        <input
          type="text"
          class="form-control"
          id="repeatNewPasswordUser"
          v-model="repeatPasswordUser"
          :type="repeatPassFieldType"
          autocomplete="off"
        />
        <i
          class="icon-eye"
          :class="iconRepeatNewPass"
          @click="seeRepeatNewPass"
          id="icon"
        ></i>
        <div
          class="error"
          v-if="submited && !$v.repeatPasswordUser.sameAsPassword"
        >
          Las contraseñas deben coincidir
        </div>
      </div>
 -->
      <template class="text-center" #modal-footer="{ cancel, hide }">
        <!-- Button with custom close trigger value -->
        <b-button class="btn-cancel" @click="cancel()"> Cancelar </b-button>
        <b-button
          class="btn-modify"
          @click-self="hide('forget')"
          @click="forgotPass"
        >
          Restaurar contraseña
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { backendUrl } from "../../../../config/index";
import axios from "axios";
import store from "../../../../store/index";
import { required, minLength, sameAs, maxLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../../../components/toastification/ToastificationContent.vue";
export default {
  props: ["nameUser", "lastnameUser", "emailUser"],
  data() {
    return {
      /* user: {}, */
      submited: false,
      currentPasswordUser: "",
      newPasswordUser: "",
      repeatPasswordUser: "",

      iconPass: "fas fa-eye",
      iconNewPass: "fas fa-eye",
      iconRepeatNewPass: "fas fa-eye",

      passFieldType: "password",
      newPassFieldType: "password",
      repeatPassFieldType: "password",
    };
  },
  validations: {
    currentPasswordUser: {
      required,
      minLength: minLength(6),
    },
    newPasswordUser: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20) 
    },
    repeatPasswordUser: {
      sameAsPassword: sameAs("newPasswordUser"),
    },
  },
  components: {
    ToastificationContent,
  },
  mounted() {},
  methods: {
   /*  seePass() {
      this.passFieldType =
        this.passFieldType === "password" ? "text" : "password";
      this.iconPass =
        this.iconPass === "fas fa-eye" ? "fas fa-eye-slash" : "fas fa-eye";
    },
    seeNewPass() {
      this.newPassFieldType =
        this.newPassFieldType === "password" ? "text" : "password";
      this.iconNewPass =
        this.iconNewPass === "fas fa-eye" ? "fas fa-eye-slash" : "fas fa-eye";
    },
    seeRepeatNewPass() {
      this.repeatPassFieldType =
        this.repeatPassFieldType === "password" ? "text" : "password";
      this.iconRepeatNewPass =
        this.iconRepeatNewPass === "fas fa-eye"
          ? "fas fa-eye-slash"
          : "fas fa-eye";
    },
    async changePassUser() {
      this.submited = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        this.$store.commit("changeLoading", true);
        const data = {
          email: this.emailUser,
          password: this.currentPasswordUser,
          newPassword: this.newPasswordUser,
        };
        try {
          const response = await axios({
            method: "put",
            url: `${backendUrl}/user/change-pass`,
            headers: {
              "Content-Type": "application/json",
            },
            data,
          });
          this.submited = false;
          this.$store.commit("changeLoading", false);
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
          this.currentPasswordUser = "";
          this.newPasswordUser = "";
          this.repeatPasswordUser = "";
          this.$bvModal.hide("modalChangePassUser");
        } catch (error) {
          this.$store.commit("changeLoading", false);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
          this.currentPasswordUser = "";
          this.newPasswordUser = "";
          this.repeatPassword = "";
          this.$bvModal.hide("modalChangePassUser");
        }
      }
    }, */
     async forgotPass() {
       this.$store.commit("changeLoading", true);
        const data = {
          email: this.emailUser,
        };
        try {
          const response = await axios({
            method: "post",
            url: `${backendUrl}/user/forgot-pass`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data,
          });
           this.$store.commit("changeLoading", false);
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
          this.$bvModal.hide("modalChangePassUser");
        } catch (error) {
           this.$store.commit("changeLoading", false);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
        }
      }
    },
};
</script>


<style scoped>
.container-pass {
  position: relative;
}
.icon-eye {
  color: #646464;
  font-size: 15px;
  position: absolute;
  right: 11px;
  top: 32px;
  font-size: 14px;
  padding-top: 5px;
  cursor: pointer;
}
.btn-close {
  display: none;
}
/deep/.modal-title {
  color: #646464;
  font-size: 17px;
}
/deep/.modal-header {
  border-bottom: none;
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
}
/deep/.modal-body {
  text-align: left;
  padding-bottom: 8px;
  padding-top: 5px;
  padding-left: 35px;
  padding-right: 35px;
}
/deep/ .modal-body .form-label {
  font-size: 14px;
  margin-bottom: 2px;
}
/deep/ .modal-footer {
  border-top: none;
  padding-top: 0;
  padding-bottom: 25px;
  padding-left: 35px;
  padding-right: 35px;
}
.btn-cancel {
  background-color: #858585;
  color: #ffffff;
  transition: 0.4s;
  border: none;
  font-size: 14px;
}
.btn-cancel:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
.btn-modify {
  background-color: #f44802;
  color: #ffffff;
  transition: 0.4s;
  border: none;
  font-size: 14px;
}
.btn-modify:hover {
  background-color: #e6784c;
  color: #ffffff;
}
</style>