<template>
  <div>
    <div class="search row justify-content-end pb-2">
      <div class="col-input col">
        <label for="name" class="form-label">Área</label>
        <input
          class="form-control"
          type="text"
          placeholder="Agregar una area"
          v-model="area"
          autocomplete="off"
        />
      </div>
      <div class="col col-add">
        <button
          class="btn btn-add"
          @click="createArea"
        >
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      <div class="error" v-if="submited && !$v.area.required">
        Este campo es requerido
      </div>
      <div class="error" v-if="submited && !$v.area.maxLength">
        Este campo debe tener menos de 100 caracteres
      </div>
    </div>
    <div>
      <div class="search row justify-content-end mt-4 pb-2">
        <div class="col-search col" v-if="items.length > 0">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Buscar"
          ></b-form-input>
        </div>
      </div>
      <b-table
        v-if="items.length > 0 && !spinnerTable"
        small
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        responsive="md"
        :filter="filter"
      >
        <!-- A virtual composite column -->
        <template #cell(delete)="data">
          <div class="text-end px-2">
            <button
              class="btn btn-sm btn-trash"
              @click="
                () => {
                  id = data.item._id;
                  $bvModal.show('modalDeleteArea');
                }
              "
            >
              <i class="fa-solid fa-trash-can"></i>
            </button>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length > 0 && !spinnerTable"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <div v-if="items.length == 0 && !spinnerTable">
        <h6 class="text-center my-4">Aún no hay un area creada</h6>
      </div>
      <div class="text-center my-5" v-if="spinnerTable">
        <b-spinner class="spinner-orange" label="Spinning"></b-spinner>
      </div>
      <ModalDeleteArea :funtionDelete="funtionDelete" />
      <!--   <ModalChangePassUser /> -->
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../../config/index";
import axios from "axios";
import store from "../../../../store/";
import { required, maxLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../../../components/toastification/ToastificationContent.vue";
import ModalDeleteArea from "./modal/Modal-deleteArea.vue";

export default {
  data() {
    return {
      submited: false,
      area: "",
      //table
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: "name", label: "Tipo", filterByFormatted: true, sortable: true, class: "text-mayus" },
        { key: "delete", label: "" },
      ],
      filter: null,
      items: [],
      spinnerTable: false,
    };
  },
  validations: {
    area: { required, maxLength: maxLength(100) },
  },
  components: {
    ToastificationContent,
    ModalDeleteArea,
  },
  mounted() {
    this.getArea();
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    async createArea() {
      this.submited = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        this.$store.commit("changeLoading", true);
        const data = {
          name: this.area,
        };

        try {
          const response = await axios({
            method: "post",
            url: `${backendUrl}/area/create`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data,
          });
          this.getArea();
          this.reloadPage();
          this.area = "";
          this.submited = false;
          this.$store.commit("changeLoading", false);
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
        } catch (error) {
          this.$store.commit("changeLoading", false);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
        }
      }
    },
    async getArea() {
      this.spinnerTable = true;
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/area/get-areas`,
        });

        if (response.status == 200) {
          this.items = response.data.areasGetted;
        }
        this.spinnerTable = false;
      } catch (error) {
        this.spinnerTable = false;
      }
    },
    funtionDelete() {
      this.deleteArea(this.id);
    },
    async deleteArea(id) {
      this.$store.commit("changeLoading", true);
      try {
        const response = await axios({
          method: "delete",
          url: `${backendUrl}/area/delete/${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.getArea();
        this.$store.commit("changeLoading", false);
        this.$toast.success(response.data.message, {
          position: "top-right",
          timeout: 2000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteArea");
      } catch (error) {
        this.$store.commit("changeLoading", false);
        this.$toast.error(error.response.data.message, {
          position: "top-right",
          timeout: 4000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteArea");
      }
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
};
</script>
<style scoped>
.spinner-orange {
  color: #f44802;
}
.form-label {
  font-size: 15px;
  margin-bottom: 2px;
}
.form-control {
  font-size: 14px;
}
.col-input {
  padding: 0px;
}
.col-add {
  margin-top: auto;
  max-width: 48px;
  padding-left: 8px;
  padding-right: 4px;
}
.btn-add {
  background-color: #858585;
  color: #ffffff;
  transition: 0.4s;
  font-size: 14px;
}
.btn-add:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*dropdown*/
/deep/ .dropdown-toggle {
  background-color: transparent !important;
  border: none;
  width: 38px;
  height: 24px;
  padding: 0;
  color: black;
  transition: 0.4s;
}
/deep/ .dropdown-toggle:hover,
.dropdown-toggle:active {
  background-color: transparent !important;
  color: #8b8b8b;
}
/deep/.dropdown-toggle::after {
  display: none;
}
/deep/.dropdown-menu {
  min-width: 10rem;
  margin-top: 0px;
  border: none;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
/deep/.dropdown-item {
  padding-top: 0px;
  color: #656565;
  transition: 0.4s;
  padding-left: 5px;
  padding-right: 5px;
}
/deep/.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #f5f5f5;
}
.d-list {
  cursor: pointer;
}
.list {
  color: #656565;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 0.5rem 13px;
  cursor: pointer;
  font-size: 13px;
}

/deep/.show > .btn-secondary.dropdown-toggle {
  background-color: #ffffff !important;
  color: #000000;
}
/*dropdown*/
/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}
</style>