<template>
  <div>
    <div class="search row justify-content-end pb-2">
      <div class="col-input col-12">
        <label for="email" class="form-label">Area</label>
        <Multiselect
          id="areas"
          v-model="tipoArea"
          :options="areas"
          track-by="name"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="true"
          placeholder="Selecione un area"
          label="name"
          :preselect-first="false"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          @input="selectArea()"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} areas seleccionadas</span
            ></template
          >
          <span slot="noOptions"> Aún no hay area.</span>
          <span slot="noResult">Oops! No se encontraron elementos.</span>
        </Multiselect>

        <!-- <select class="form-select" id="etiquetas" v-model="tipoArea">
          <option disabled value="">Seleccione una area</option>
          <option v-for="area in areas" :key="area.name" :value="area">
            {{ area.name }}
          </option>
        </select> -->
        <p class="p-keywords" v-if="tipoArea.keywords == 0">
          Aún no hay palabras claves agregadas
        </p>
        <p
          class="p-keywords"
          v-if="tipoArea.keywords && tipoArea.keywords != 0"
        >
          Palabras claves agregadas
        </p>
        <span
          class="span-key"
          v-for="keyword in tipoArea.keywords"
          :key="keyword"
          :value="keyword"
          @click="
            idArea = tipoArea._id;
            deleteKeyword = keyword;
            $bvModal.show('modalDeleteKeyword');
          "
        >
          <div class="badge rounded-pill bg-orange">{{ keyword }}</div>
          <span class="delete-key"> <i class="fa-solid fa-xmark"></i> </span>
        </span>
        <span> </span>
        <div class="error" v-if="submited && !$v.tipoArea.required">
          Este campo es requerido
        </div>
      </div>
      <div class="col-input col mt-3">
        <label for="name" class="form-label">Palabra clave</label>
        <input
          class="form-control"
          type="text"
          placeholder="Agregar una palabra clave para registrar en el area seleccionada"
          v-model="keyword"
          autocomplete="off"
        />
      </div>
      <div class="col col-add">
        <button class="btn btn-add" @click="createKeyword(tipoArea._id)">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      <div class="error p-0" v-if="submited && !$v.keyword.required">
        Este campo es requerido
      </div>
      <div class="error p-0" v-if="submited && !$v.keyword.maxLength">
        Este campo debe tener menos de 100 caracteres
      </div>
    </div>
    <ModalDeleteKeyword :funtionDelete="funtionDelete" />
  </div>
</template>
<script>
import { backendUrl } from "../../../../config/index";
import axios from "axios";
import store from "../../../../store";
import Multiselect from "vue-multiselect";
import { required, maxLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../../../components/toastification/ToastificationContent.vue";
import ModalDeleteKeyword from "./modal/Modal-deleteKeyword.vue";
export default {
  data() {
    return {
      submited: false,
      area: "",
      areas: [],
      keyword: "",
      tipoArea: "",

      idArea: "",
      deleteKeyword: "",
    };
  },
  validations: {
    tipoArea: { required },
    keyword: { required, maxLength: maxLength(100) },
  },
  components: {
    ToastificationContent,
    Multiselect,
    ModalDeleteKeyword,
  },
  mounted() {
    this.getArea();
  },
  methods: {
    async createKeyword(idArea) {
      this.submited = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        this.$store.commit("changeLoading", true);
        const data = {
          keyword: this.keyword,
        };
        try {
          const response = await axios({
            method: "post",
            url: `${backendUrl}/area/create-keyword/${idArea}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data,
          });
          this.getArea();
          this.tipoArea = "";
          this.keyword = "";
          this.submited = false;
          this.$store.commit("changeLoading", false);
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
        } catch (error) {
          this.$store.commit("changeLoading", false);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
        }
      }
    },
    async getArea() {
      this.spinnerTable = true;
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/area/get-areas`,
        });

        if (response.status == 200) {
          this.areas = response.data.areasGetted;
        }
        this.spinnerTable = false;
      } catch (error) {
        this.spinnerTable = false;
      }
    },
    funtionDelete() {
      this.deleteArea(this.idArea, this.deleteKeyword);
    },
    async deleteArea(idArea, keyword) {
      this.$store.commit("changeLoading", true);
      try {
        const response = await axios({
          method: "put",
          url: `${backendUrl}/area/delete-keyword`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            idArea,
            keyword,
          },
        });
        this.getArea();
        this.$store.commit("changeLoading", false);
        this.$toast.success(response.data.message, {
          position: "top-right",
          timeout: 2000,
          hideProgressBar: true,
        });
        this.tipoArea = "";
        this.keyword = "";
        this.$bvModal.hide("modalDeleteKeyword");
      } catch (error) {
        this.$store.commit("changeLoading", false);
        this.$toast.error(error.response.data.message, {
          position: "top-right",
          timeout: 4000,
          hideProgressBar: true,
        });
        this.tipoArea = "";
        this.keyword = "";
        this.$bvModal.hide("modalDeleteKeyword");
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
.spinner-orange {
  color: #f44802;
}
.form-label {
  font-size: 15px;
  margin-bottom: 2px;
}
.form-control,
.form-select {
  font-size: 14px;
}
.col-input {
  padding: 0px;
}
.col-add {
  margin-top: auto;
  max-width: 48px;
  padding-left: 8px;
  padding-right: 4px;
}
.btn-add {
  background-color: #858585;
  color: #ffffff;
  transition: 0.4s;
  font-size: 14px;
}
.btn-add:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*dropdown*/
/deep/ .dropdown-toggle {
  background-color: transparent !important;
  border: none;
  width: 38px;
  height: 24px;
  padding: 0;
  color: black;
  transition: 0.4s;
}
/deep/ .dropdown-toggle:hover,
.dropdown-toggle:active {
  background-color: transparent !important;
  color: #8b8b8b;
}
/deep/.dropdown-toggle::after {
  display: none;
}
/deep/.dropdown-menu {
  min-width: 10rem;
  margin-top: 0px;
  border: none;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
/deep/.dropdown-item {
  padding-top: 0px;
  color: #656565;
  transition: 0.4s;
  padding-left: 5px;
  padding-right: 5px;
}
/deep/.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #f5f5f5;
}
.d-list {
  cursor: pointer;
}
.list {
  color: #656565;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 0.5rem 13px;
  cursor: pointer;
  font-size: 13px;
}

/deep/.show > .btn-secondary.dropdown-toggle {
  background-color: #ffffff !important;
  color: #000000;
}
/*dropdown*/
/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}
.p-keywords {
  margin-bottom: 0px;
  font-size: 12px;
  margin-top: 5px;
}
.bg-orange {
  background-color: #f44802;
  padding-left: 12px;
  padding-right: 5px;
  /* margin-right: 3px; */
  text-transform: uppercase;
}

.rounded-pill {
  border-radius: 50rem 0px 0px 50rem !important;
}
.delete-key {
  font-size: 11px;
  background-color: #f44802;
  border-radius: 0px 50rem 50rem 0px !important;
  padding: 2px 8px 4px 6px;
  color: #ffffff;
  padding: 2px 8px 4px 6px;
}
.delete-key:hover {
  background-color: #e6784c;
}
.span-key {
  margin-right: 2px;
}

@media only screen and (max-width: 2000px) {
  .delete-key {
    padding: 3px 8px 2px 6px;
  }
}
</style>