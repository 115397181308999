var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-12 mt-5"},[_c('Nav',{attrs:{"submenu":_vm.submenu,"backSearch":_vm.backSearch}})],1),_vm._m(0),_c('div',{staticClass:"col-12 px-4 pb-4 mt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Número")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.numero),expression:"numero"}],staticClass:"form-control",attrs:{"type":"number","id":"name","placeholder":"Ingrese un número","autocomplete":"off"},domProps:{"value":(_vm.numero)},on:{"input":function($event){if($event.target.composing){ return; }_vm.numero=$event.target.value}}})])]),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"year"}},[_vm._v("Año")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.anio),expression:"anio"}],staticClass:"form-control",attrs:{"type":"number","id":"name","placeholder":"Ingrese un año","autocomplete":"off"},domProps:{"value":(_vm.anio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.anio=$event.target.value}}})])]),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"legislacion"}},[_vm._v("Tipo de documento")]),_c('Multiselect',{attrs:{"id":"legislation","options":_vm.documento,"track-by":"type","multiple":false,"close-on-select":true,"clear-on-select":true,"preserve-search":true,"placeholder":"Selecione un documento","label":"type","preselect-first":false,"selectLabel":"","deselectLabel":"","selectedLabel":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" documentos seleccionados")]):_vm._e()]}}]),model:{value:(_vm.tipoDocumento),callback:function ($$v) {_vm.tipoDocumento=$$v},expression:"tipoDocumento"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Aún no hay documentos. ")]),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Oops! No se encontraron elementos.")])])],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"email"}},[_vm._v("Asunto")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.asunto),expression:"asunto"}],staticClass:"form-control",attrs:{"type":"text","id":"email","placeholder":"Ingrese un asunto","autocomplete":"off"},domProps:{"value":(_vm.asunto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.asunto=$event.target.value}}})])]),_c('div',{staticClass:"col-lg-6 col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"legislacion"}},[_vm._v("Área")]),_c('Multiselect',{attrs:{"id":"areas","options":_vm.areas,"track-by":"name","multiple":false,"close-on-select":true,"clear-on-select":true,"preserve-search":true,"placeholder":"Selecione una palabra clave","label":"name","preselect-first":false,"selectLabel":"","deselectLabel":"","selectedLabel":""},on:{"input":function($event){return _vm.selectArea()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" areas seleccionadas")]):_vm._e()]}}]),model:{value:(_vm.tipoArea),callback:function ($$v) {_vm.tipoArea=$$v},expression:"tipoArea"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Aún no hay area.")]),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Oops! No se encontraron elementos.")])])],1)]),_c('div',{staticClass:"col-lg-6 col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"legislacion"}},[_vm._v("Palabra clave")]),_c('Multiselect',{attrs:{"id":"keyword","options":_vm.palabra,"track-by":"","multiple":true,"close-on-select":false,"clear-on-select":true,"preserve-search":true,"placeholder":"Selecione un área","label":"","preselect-first":false,"selectLabel":"","deselectLabel":"","selectedLabel":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" areas seleccionadas")]):_vm._e()]}}]),model:{value:(_vm.palabraClave),callback:function ($$v) {_vm.palabraClave=$$v},expression:"palabraClave"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Aún no hay palabras claves.")]),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Oops! No se encontraron elementos.")])])],1)]),_c('div',{staticClass:"col-12 text-end"},[_c('button',{staticClass:"btn btn-create btn-sm",on:{"click":_vm.search}},[_vm._v("Buscar")])])])]),_c('div',{staticClass:"col-12 px-4 mt-4"},[_c('div',{staticClass:"search row justify-content-end mt-4 pb-2"},[(_vm.showTable)?_c('div',{staticClass:"col-search col"},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Buscar"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1):_vm._e()]),(_vm.showTable)?_c('b-table',{attrs:{"small":"","fields":_vm.fields,"items":_vm.documents,"per-page":_vm.perPage,"current-page":_vm.currentPage,"responsive":"md","filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(view)",fn:function(data){return [_c('div',{staticClass:"text-end px-2"},[_c('button',{staticClass:"btn",on:{"click":function () {
                _vm.details = data.item;
                _vm.seeDocumento(_vm.details);
              }}},[_c('i',{staticClass:"fa-solid fa-eye"})])])]}}],null,false,2801317415)}):_vm._e(),(_vm.showTable)?_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.showTable == false)?_c('div',[_c('h6',{staticClass:"text-center my-4"},[_vm._v(" No se encontraron archivos en esta búsqueda ")])]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"titles"},[_c('h5',[_vm._v("Búsqueda de documentos")]),_c('p',[_vm._v("Ingresa los datos para buscar un documento")])])])}]

export { render, staticRenderFns }