<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :backSearch="backSearch" />
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Búsqueda de documentos</h5>
        <p>Ingresa los datos para buscar un documento</p>
      </div>
    </div>
    <div class="col-12 px-4 pb-4 mt-2">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="name" class="form-label">Número</label>
            <input
              type="number"
              class="form-control"
              id="name"
              v-model="numero"
              placeholder="Ingrese un número"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="year" class="form-label">Año</label>
            <input
              type="number"
              class="form-control"
              id="name"
              v-model="anio"
              placeholder="Ingrese un año"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
          <div class="mb-3">
            <label for="legislacion" class="form-label">Tipo de documento</label>
            <Multiselect
              id="legislation"
              v-model="tipoDocumento"
              :options="documento"
              track-by="type"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione un documento"
              label="type"
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} documentos seleccionados</span
                ></template
              >
              <span slot="noOptions"> Aún no hay documentos. </span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>
            </Multiselect>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Asunto</label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="asunto"
              placeholder="Ingrese un asunto"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-lg-6 col-12">
          <div class="mb-3">
            <label for="legislacion" class="form-label">Área</label>
            <Multiselect
              id="areas"
              v-model="tipoArea"
              :options="areas"
              track-by="name"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione una palabra clave"
              label="name"
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @input="selectArea()"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} areas seleccionadas</span
                ></template
              >
              <span slot="noOptions"> Aún no hay area.</span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>
            </Multiselect>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="mb-3">
            <label for="legislacion" class="form-label">Palabra clave</label>
            <Multiselect
              id="keyword"
              v-model="palabraClave"
              :options="palabra"
              track-by=""
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione un área"
              label=""
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} areas seleccionadas</span
                ></template
              >
              <span slot="noOptions"> Aún no hay palabras claves.</span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>
            </Multiselect>
          </div>
        </div>

        <div class="col-12 text-end">
          <button class="btn btn-create btn-sm" @click="search">Buscar</button>
          <!-- <ModalConfirmDocument/> -->
        </div>
      </div>
    </div>
    <div class="col-12 px-4 mt-4">
      <div class="search row justify-content-end mt-4 pb-2">
        <div class="col-search col" v-if="showTable">
          <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Buscar"
            ></b-form-input>
        </div>
      </div>
      <b-table
        v-if="showTable"
        small
        :fields="fields"
        :items="documents"
        :per-page="perPage"
        :current-page="currentPage"
        responsive="md"
        :filter="filter"
      >
        <!-- A virtual composite column -->
        <template #cell(view)="data">
          <div class="text-end px-2">
            <button
              class="btn"
              @click="
                () => {
                  details = data.item;
                  seeDocumento(details);
                }
              "
            >
              <i class="fa-solid fa-eye"></i>
            </button>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="showTable"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <div v-if="showTable == false">
        <h6 class="text-center my-4">
          No se encontraron archivos en esta búsqueda
        </h6>
      </div>
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import store from "./../../../store/index";
import Nav from "../../public/nav/Nav.vue";
import { useToast } from "vue-toastification";
import Multiselect from "vue-multiselect";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";
/* import ModalConfirmDocument from './modal/ModalConfirmDocument.vue' */
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      backSearch: true,
      submenu: "searchDocument",
      numero: "",
      anio: "",
      tipoDocumento: "",
      documento: "",
      asunto: "",
      tipoArea: "",
      areas: [],
      palabra: [],
      palabraClave: "",
      showTable: null,
      details: [],
      /*table*/
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: "tipoDocumento", label: "Tipo", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "numero", label: "Número", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "anioSancion", label: "Año", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "asunto", label: "Asunto", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "view", label: "" },
      ],
      documents: [],
      filter: null,
    };
  },
  components: {
    Nav,

    /* ModalConfirmDocument */
    Multiselect,
    ToastificationContent,
  },
  mounted() {
    this.getDocuments();
    this.getArea();
  },
  methods: {
    
    selectArea() {
      this.palabra = this.tipoArea.keywords;
    },

    async search() {
      this.$store.commit("changeLoading", true);
      const filters = {
        ...(this.numero && { numero: this.numero }),
        ...(this.anio && { anioSancion: this.anio }),
        ...(this.tipoDocumento && {
          tipoDocumento: this.tipoDocumento.type,
        }),
        ...(this.asunto && { asunto: this.asunto }),
        ...(this.tipoArea && { area: this.tipoArea.name }),
        ...(this.palabraClave && { palabrasClave: this.palabraClave }),
      };

      try {
        const response = await axios({
          method: "post",
          url: `${backendUrl}/document/get`,
          data: filters,
        });
        if (response.status == 200) {
          this.showTable = true;
          this.documents = response.data.documentGetted;
        }
        this.$store.commit("changeLoading", false);
        this.$toast.success(response.data.message, {
          position: "top-right",
          timeout: 2000,
          hideProgressBar: true,
        });
      } catch (error) {
        this.showTable = false;
        this.$store.commit("changeLoading", false);
        this.$toast.error(error.response.data.message, {
          position: "top-right",
          timeout: 4000,
          hideProgressBar: true,
        });
      }
    },
    async downloadItem({ url, label, fileName }) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.setAttribute('download', fileName)
      link.click();
      URL.revokeObjectURL(link.href);
    },
    seeDocumento(details) {
      this.$store.commit("saveDocumentSelect", details);
      this.$router.push({ path: "/document-detail" });
    },

    async getDocuments() {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/legislation/get-legislations`,
        });
        if (response.status == 200) {
          this.documento = response.data.legislationsGetted;
        }
      } catch (error) {
      }
    },
    async getArea() {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/area/get-areas`,
        });
        if (response.status == 200) {
          this.areas = response.data.areasGetted;
        }
      } catch (error) {
      }
    },
  },
  computed: {
    rows() {
      return this.documents.length;
    },
    sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
  },
};
</script>
<style scoped>
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.form-label {
  font-size: 14px;
  margin-bottom: 2px;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  background: #f44802;
  color: white;
  padding: 9px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}

/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*dropdown*/
/deep/ .dropdown-toggle {
  background-color: transparent !important;
  border: none;
  width: 38px;
  height: 24px;
  padding: 0;
  color: black;
  transition: 0.4s;
}
/deep/ .dropdown-toggle:hover,
.dropdown-toggle:active {
  background-color: transparent !important;
  color: #8b8b8b;
}
/deep/.dropdown-toggle::after {
  display: none;
}
/deep/.dropdown-menu {
  min-width: 10rem;
  margin-top: 0px;
  border: none;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
/deep/.dropdown-item {
  padding-top: 0px;
  color: #656565;
  transition: 0.4s;
  padding-left: 5px;
  padding-right: 5px;
}
/deep/.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #f5f5f5;
}
.d-list {
  cursor: pointer;
}
.list {
  color: #656565;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 0.5rem 13px;
  cursor: pointer;
  font-size: 13px;
}

/deep/.show > .btn-secondary.dropdown-toggle {
  background-color: #ffffff !important;
  color: #000000;
}
/*dropdown*/
/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}
</style>