<template>
  <div>
    <div class="search row justify-content-end pb-2">
      <div class="col-input col">
        <label for="name" class="form-label">Nueva categoría</label>
        <input
          class="form-control"
          type="text"
          placeholder="Agregar una nueva categoría"
          v-model="newCategory"
          autocomplete="off"
        />
      </div>

      <!-- <div class="col col-add">
        <button class="btn btn-add" @click="addCategory">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div> -->
      <div class="error" v-if="submited && !$v.newCategory.required">
        Este campo es requerido
      </div>
      <div class="error" v-if="submited && !$v.newCategory.maxLength">
        Este campo debe tener menos de 50 caracteres
      </div>
    </div>

    <div
      class="search row justify-content-end pb-2"
      v-for="(input, index) in inputs" :key="index"
    >
      <div class="col-md-6 col-12 input-left">
        <label for="name" class="form-label">Campos</label>
        <input
          class="form-control"
          type="text"
          placeholder="Agregar un campo"
          v-model="input.one"
          autocomplete="off"
          maxlength="50"
        />

       <!--  <div class="error" v-if="submited && !$v.input.one.required">
          Este campo es requerido
        </div>
        <div class="error" v-if="submited && !$v.input.one.maxLength">
          Este campo debe tener menos de 50 caracteres
        </div> -->
      </div>
      <div class="col-md-6 col-12 input-right">
        <label for="name" class="form-label">Tipo de campo</label>
        <Multiselect
          id="keyword"
          v-model="input.two"
          :options="types"
          track-by="option"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="true"
          placeholder="Selecione un tipo de campo"
          label="option"
          :preselect-first="false"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
        >
          <span slot="noOptions"> Aún no hay tipo de campo.</span>
          <span slot="noResult">Oops! No se encontraron elementos.</span>
        </Multiselect>
      </div>
      <!--       <div class="col-md-6 col-12">
        {{ input.one }}
      </div>
      <div class="col-md-6 col-12">
        {{ input.two.type }}
      </div> -->
      <!-- <input
          class="form-control"
          type="text"
          placeholder="Agregar una campo"
          v-model="input.two"
          autocomplete="off"
        /> -->
      <!-- <input type="text" v-model="input.one" /> - {{ input.one }}
      <input type="text" v-model="" /> - {{ input.two }} -->
      <div class="col-12 p-0 text-end mt-1">
        <button class="btn btn-sm btn-dark" @click="deleteRow(index)">
          Eliminar
        </button>
      </div>
    </div>
    <div class="col-12 my-2">
      <button class="btn btn-add" @click="addRow">Agregar campo</button>
    </div>
    <div class="col-12 text-end my-2">
      <button class="btn btn-orange" @click="createCategory">
        Crear categoría
      </button>
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../../config/index";
import axios from "axios";
import store from "../../../../store";
import Multiselect from "vue-multiselect";
import { required, maxLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../../../components/toastification/ToastificationContent.vue";
import ModalDeleteLegislation from "./modal/Modal-deleteLegislation.vue";
export default {
  props: ["getSpecialCategory"],
  data() {
    return {
      submited: false,
      codeResponse: "",
      inputs: [],
      newCategory: "",
      nameFields: "",
      types: [
        {
          type: "text",
          option: "Texto",
        },
        {
          type: "number",
          option: "Número",
        },
        {
          type: "date",
          option: "Fecha",
        },
        {
          type: "month",
          option: "Mes",
        },
      ],
      spinnerTable: false,
    };
  },
  validations: {
    newCategory: { required, maxLength: maxLength(50) },
    /* inputs:{
     one: { required, maxLength: maxLength(50) }
    } */
  },
  components: {
    Multiselect,
    ToastificationContent,
    ModalDeleteLegislation,
  },
  mounted() {},
  methods: {
    async createCategory() {
      this.submited = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        this.$store.commit("changeLoading", true);
        const fields = this.inputs.map(({ one, two }) => ({
          nameField: one,
          type: two.type,
        }));

        const data = { name: this.newCategory.toUpperCase(), fields };
        try {
          const response = await axios({
            method: "post",
            url: `${backendUrl}/special-category/create`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data,
          });
          this.submited = false;
          this.newCategory = "";
          this.inputs = [];
          this.$store.commit("changeLoading", false);
          this.codeResponse = response.data.code;
          if (this.codeResponse === 1) {
            return this.$toast.error(response.data.message, {
              position: "top-right",
              timeout: 4000,
              hideProgressBar: true,
            });
          }
          this.$toast.success("Se creó con éxito la categoría.", {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
          this.getSpecialCategory()
        } catch (error) {
          this.$store.commit("changeLoading", false);
          this.$toast.error("Ocurrió un error. Intente nuevamente", {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
        }
      }
    },
    addRow() {
      this.inputs.push({
        one: "",
        two: "",
      });
    },
    deleteRow(index) {
      this.inputs.splice(index, 1);
    },
  },
  computed: {
    /*  rows() {
      return this.items.length;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    }, */
  },
};
</script>
<style scoped>
.btn-orange {
  background-color: #f44802;
  color: #ffffff;
  transition: 0.4s;
  font-size: 14px;
}
.btn-orange:hover {
  background-color: #e6784c;
  color: #ffffff;
}
.input-left {
  padding-left: 0px;
}
.input-right {
  padding-right: 0px;
}
.spinner-orange {
  color: #f44802;
}
.form-label {
  font-size: 15px;
  margin-bottom: 2px;
}
.form-control {
  font-size: 14px;
}
.col-input {
  padding: 0px;
}
.col-add {
  margin-top: auto;
  max-width: 48px;
  padding-left: 8px;
  padding-right: 4px;
}
.btn-add {
  background-color: #858585;
  color: #ffffff;
  transition: 0.4s;
  font-size: 14px;
}
.btn-add:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
.error {
  padding: 0;
}

/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*dropdown*/
/deep/ .dropdown-toggle {
  background-color: transparent !important;
  border: none;
  width: 38px;
  height: 24px;
  padding: 0;
  color: black;
  transition: 0.4s;
}
/deep/ .dropdown-toggle:hover,
.dropdown-toggle:active {
  background-color: transparent !important;
  color: #8b8b8b;
}
/deep/.dropdown-toggle::after {
  display: none;
}
/deep/.dropdown-menu {
  min-width: 10rem;
  margin-top: 0px;
  border: none;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
/deep/.dropdown-item {
  padding-top: 0px;
  color: #656565;
  transition: 0.4s;
  padding-left: 5px;
  padding-right: 5px;
}
/deep/.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #f5f5f5;
}
.d-list {
  cursor: pointer;
}
.list {
  color: #656565;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 0.5rem 13px;
  cursor: pointer;
  font-size: 13px;
}

/deep/.show > .btn-secondary.dropdown-toggle {
  background-color: #ffffff !important;
  color: #000000;
}
/*dropdown*/
/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}

.btn-trash i:hover {
  color: #8a8a8a;
  transition: 0.4s;
}
</style>