<template>
  <div class="row bg-login justify-content-center">
    <div class="col card-login shadow-sm">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <img src="../../assets/logo.png" alt="" />
        </div>
        <div class="col-12 text-center">
          <h5>INGRESAR AL SITIO</h5>
        </div>
        <div class="col-10 pb-5">
          <form action="" autocomplete="off">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="text"
                class="form-control"
                id="email"
                name="email"
                v-model="email"
                placeholder="Ingresa tu email"
                autocomplete="off"
              />

              <div
                class="error-loggin"
                v-if="
                  (submited && !$v.email.required) ||
                  (submited && !$v.email.email)
                "
              >
                Este campo es requerido
              </div>
            </div>
            <div class="form-group mt-5 container-pass">
              <label for="pass">Contraseña</label>
              <input
                class="form-control"
                id="pass"
                name="pass"
                v-model="pass"
                placeholder="Ingresa tu contraseña"
                autocomplete="off"
                :type="passFieldType"
              />
              <i
                class="icon-eye"
                :class="iconPass"
                @click="seePass"
                id="icon"
              ></i>
              <div class="error-loggin" v-if="submited && !$v.pass.required">
                Este campo es requerido
              </div>
              <div class="error-loggin" v-if="submited && !$v.pass.minLength">
                La contraseña debe ser mas larga
              </div>
              <router-link to="/email-change-pass" class="restore-pass">
                ¿Olvidaste tu contraseña?
              </router-link>
            </div>
          </form>
          <div class="text-center">
            <button class="btn btn-sm btn-white mt-5" id="login" @click="login"  v-on:keyup.enter="login()">Ingresar</button>
            <!-- <input type="text" class="btn btn-sm btn-white mt-5" id="login" v-on:keyup.enter="login()"/> -->
          </div>

          <div class="text-center p-back">
            <router-link to="/">
              <p class="">VOLVER</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { backendUrl } from "./../../config/index";
import store from "../../store/index";
import { required, email, minLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../components/toastification/ToastificationContent.vue";

export default {
  name: "Login",
  data() {
    return {
      submited: false,
      email: "",
      pass: "",
      iconPass: "fas fa-eye",
      passFieldType: "password",
      flat: true
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    pass: {
      required,
      minLength: minLength(3),
    },
  },
  components: {
    ToastificationContent,
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'Enter' && this.flat) {

       this.login()
      }
    });

  },

  methods: {
    seePass() {
      this.passFieldType =
        this.passFieldType === "password" ? "text" : "password";
      this.iconPass =
        this.iconPass === "fas fa-eye" ? "fas fa-eye-slash" : "fas fa-eye";
    },
    async login() {
      this.submited = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        this.$store.commit("changeLoading", true);
        const data = { email: this.email, password: this.pass };

        try {
          const response = await axios({
            method: "post",
            url: `${backendUrl}/user/login`,
            headers: {
              "Content-Type": "application/json",
            },
            data,
          });
          localStorage.setItem("token", response.data.token);
          this.$store.commit("changeLoading", false);
          this.$router.push("/home");
          this.flat = false;
          this.$toast.success("¡Bienvenido!", {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
        } catch (error) {
          this.$store.commit("changeLoading", false);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.bg-login {
  width: 100vw;
  height: 100vh;
  background: #e3e3e3;
  display: flex;
  align-items: center;
}
.card-login {
  background: #f44802;
  max-width: 400px;
  border-radius: 12px;
}
.card-login img {
  margin-top: 50px;
  width: 170px;
}
h5 {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: 40px 0;
}

.container-pass {
  position: relative;
}
.icon-eye {
  color: white;
  font-size: 15px;
  position: absolute;
  right: 11px;
  top: 31px;
  font-size: 14px;
  padding-top: 5px;
  cursor: pointer;
}
label {
  text-align: left;
  color: white;
  font-size: 15px;
}

input {
  font-size: 14px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #ffffffd3;
}
input:focus,
input:active,
input:visited {
  background-color: transparent !important;
  color: #ffffff;
  border-color: transparent !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f44802 inset;
  -webkit-text-fill-color: #ffffff;
}
::-webkit-input-placeholder {
  color: white;
} /* WebKit */
::-moz-placeholder {
  color: white;
} /* Firefox 19+ */
input::placeholder {
  color: rgb(221, 221, 221);
}
.restore-pass {
  display: block;
  text-align: end;
  font-size: 12px;
  text-decoration: none;
  color: #ffffff;
  margin-top: 5px;
  transition: 0.4s;
}
.restore-pass:hover {
  color: #d6d6d6;
}

.btn-white {
  border-radius: 8px;
  padding: 8px 25px;
  background-color: white;
  color: #f44802;
  /*margin-left: calc(50% - 69px);*/
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.4s;
}
.btn-white:hover {
  background-color: rgb(226, 226, 226);
  color: #f44802;
}
.p-back p {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 13px;
  color: white;
}
a{
  text-decoration: none;
}
</style>