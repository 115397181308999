<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :back="back" />
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Crear un nuevo usuario</h5>
        <p>Ingresa los datos de carga del usuario nuevo</p>
      </div>
    </div>
    <div class="col-12 px-4 pb-4 mt-2">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="mb-3">
            <label for="name" class="form-label">Nombre</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="name"
              autocomplete="off"
              placeholder="Ingresa un nombre"
            />
            <div class="error" v-if="submited && !$v.name.required">
              Este campo es requerido
            </div>
            <div class="error" v-if="submited && !$v.name.maxLength">
              Este campo debe tener menos de 20 caracteres
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="mb-3">
            <label for="lastname" class="form-label">Apellido</label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              v-model="lastname"
              autocomplete="off"
              placeholder="Ingresa un apellido"
            />
            <div class="error" v-if="submited && !$v.lastname.required">
              Este campo es requerido
            </div>
            <div class="error" v-if="submited && !$v.lastname.maxLength">
              Este campo debe tener menos de 20 caracteres
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="email"
              autocomplete="off"
              placeholder="Ingresa un email"
            />
            <div
              class="error"
              v-if="
                (submited && !$v.email.required) ||
                (submited && !$v.email.email)
              "
            >
              Este campo es requerido
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="isAdmin"
                v-model="checked"
                autocomplete="off"
              />
              <label class="form-check-label" for="isAdmin">
                Este usuario es administrador
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 text-end">
          <button class="btn btn-create" @click="createUser">Crear</button>
        </div>
      </div>
    </div>

    <div class="col-12 px-4 mt-4">
      <div class="search row justify-content-end pb-2">
        <div class="col-text col-lg col-md-6 mb-lg-0 mb-2">
          <h5>Lista de usuarios</h5>
        </div>
        <div class="col-search col-lg col-md-6" v-if="items.length > 0 && !spinnerTable">
          <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Buscar"
            ></b-form-input>
        </div>
      </div>
      <b-table
        v-if="items.length > 0 && !spinnerTable"
        small
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        responsive="md"
        :filter="filter"
      >
        <!-- A custom formatted column -->
        <template #cell(isAdmin)="data">
          <span v-if="data.value == true"> Administrador </span>
          <span v-if="data.value == false"> Colaborador </span>
        </template>
        <!-- A virtual composite column -->
        <template #cell(option)="data" style="width: 3%">
          <b-dropdown id="dropdown-right" right class="m-2 btn-avatar">
            <template #button-content class="btn-avatar">
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button
              class="d-list"
              @click="
                () => {
                  id = data.item._id;
                  $bvModal.show('modalDeleteUser');
                }
              "
            >
              <label class="list">Eliminar</label>
            </b-dropdown-item-button>
            <b-dropdown-item-button class="d-list"
            @click="
                () => {
                  nameUser = data.item.name
                  lastnameUser = data.item.name
                  emailUser = data.item.email
                  id = data.item._id;
                  passwordUser = data.item.password
                  $bvModal.show('modalChangePassUser');
                }
              ">
              <label class="list">
                Cambiar contraseña</label
              ></b-dropdown-item-button
            >
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length > 0 && !spinnerTable"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <div v-if="items.length == 0 && !spinnerTable">
        <h6 class="text-center my-4">Aun no hay usuarios creados</h6>
      </div>
      <div class="text-center my-5" v-if="spinnerTable">
      <b-spinner class="spinner-orange" label="Spinning"></b-spinner>
    </div>
      <ModalDeleteUser :funtionDelete="funtionDelete" />
      <ModalChangePassUser :nameUser="nameUser" :lastnameUser="lastnameUser" :emailUser="emailUser" :passwordUser="passwordUser"/>
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import store from "../../../store/";
import Nav from "../../public/nav/Nav.vue";
import { required, email, maxLength} from "vuelidate/lib/validators";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";
import ModalDeleteUser from "./modal/Modal-deleteUser.vue";
import ModalChangePassUser from "./modal/Modal-changePassUser.vue";
export default {
  data() {
    return {
      back: true,
      submited: false,
      submenu: "newUser",
      name: "",
      lastname: "",
      email: "",
      checked: false,

      //table
      users: {},
      submenu: "newUser",
      name: "",
      lastname: "",
      email: "",
      id: 0,
      accion: "",
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: "name", label: "Nombre", filterByFormatted: true, sortable: true, class: "text-mayus" },
        { key: "lastname", label: "Apellido", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "email", label: "Email", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "isAdmin", label: "Tipo de usuario", filterByFormatted: true, sortable: true, class: "text-mayus"  },
        { key: "option", label: "" },
      ],
      filter: null,
      user: {},
      items: [],

      nameUser:"",
      lastnameUser:"",
      emailUser:"",
      passwordUser: "",
      spinnerTable: false,
    };
  },
  validations: {
    name: { required, maxLength: maxLength(20)},
    lastname: { required, maxLength: maxLength(20)},
    email: { required, email },
  },
  components: {
    Nav,
    ToastificationContent,
    ModalDeleteUser,
    ModalChangePassUser,
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async createUser() {
      this.submited = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        this.$store.commit("changeLoading", true);
        const data = {
          name: this.name,
          lastname: this.lastname,
          email: this.email,
          isAdmin: this.checked,
        };

        try {
          const response = await axios({
            method: "post",
            url: `${backendUrl}/user/create-collaborator`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data,
          });

          this.getUser();
          this.name = "";
          this.lastname = "";
          this.email = "";
          this.submited = false;
          this.$store.commit("changeLoading", false);
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
        } catch (error) {
          this.$store.commit("changeLoading", false);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
        }
      }
    },
    async getUser() {
      this.spinnerTable = true
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/user/get-users`,
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        if (response.status == 200) {
          this.items = response.data.usersGetted;
        }
        this.spinnerTable = false
      } catch (error) {
        this.spinnerTable = false
      }
    },
    funtionDelete() {
      this.deleteUser(this.id);
    },

    async deleteUser(id) {
      this.$store.commit("changeLoading", true);
      try {
        const response = await axios({
          method: "delete",
          url: `${backendUrl}/user/delete-user/${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.getUser();
        this.$store.commit("changeLoading", false);
        this.$toast.success(response.data.message, {
          position: "top-right",
          timeout: 2000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteUser");
      } catch (error) {
        this.$store.commit("changeLoading", false);
        this.$toast.error(error.response.data.message, {
          position: "top-right",
          timeout: 4000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteUser");
      }
    },
    
  },
  computed: {
    rows() {
      return this.items.length;
    },
    sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
  },
};
</script>
<style scoped>
.spinner-orange{
  color: #f44802;
}
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.form-label {
  font-size: 15px;
  margin-bottom: 2px;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  background: #f44802;
  color: white;
  padding: 9px 35px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}

/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*dropdown*/
/deep/ .dropdown-toggle {
  background-color: transparent !important;
  border: none;
  width: 38px;
  height: 24px;
  padding: 0;
  color: black;
  transition: 0.4s;
}
/deep/ .dropdown-toggle:hover,
.dropdown-toggle:active {
  background-color: transparent !important;
  color: #8b8b8b;
}
/deep/.dropdown-toggle::after {
  display: none;
}
/deep/.dropdown-menu {
  min-width: 10rem;
  margin-top: 0px;
  border: none;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
/deep/.dropdown-item {
  padding-top: 0px;
  color: #656565;
  transition: 0.4s;
  padding-left: 5px;
  padding-right: 5px;
}
/deep/.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #f5f5f5;
}
.d-list {
  cursor: pointer;
}
.list {
  color: #656565;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 0.5rem 13px;
  cursor: pointer;
  font-size: 13px;
}

/deep/.show > .btn-secondary.dropdown-toggle {
  background-color: #ffffff !important;
  color: #000000;
}
/*dropdown*/
/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}
</style>