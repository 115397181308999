<template>
  <div>
    <b-dropdown id="dropdown-right" right class=" btn-avatar">
      <template #button-content class="btn-avatar" >
        <i class="fa-solid fa-user"></i>
      </template>
      <router-link to="/home">           
      <b-dropdown-item-button class="d-list">
        <i class="fa-solid fa-house"></i>
        <label class="list-user" >
          Página principal</label
        ></b-dropdown-item-button
      >
      </router-link>
      <b-dropdown-item-button class="d-list" v-b-modal.modalChangePass>
        <i class="fa-solid fa-gear"></i>
        <label class="list-user" >
          Cambiar contraseña</label
        ></b-dropdown-item-button
      >

      <hr />
      <div class="text-center mb-1 mt-2">
        <button class="btn btn-sm btn-orange" v-b-modal.logout>Cerrar sesión</button>
      </div>
    </b-dropdown>
    <LogoutModal :logout="logout" />
    <ModalChangePass/>
  </div>
</template>

<script>
import LogoutModal from "./modal/Modal-logout.vue";
import ModalChangePass from './modal/Modal-changePass.vue'
export default {
  props: ["logout"],
   data() {
    return {
    };
  },
  components: {
    ModalChangePass,
    LogoutModal
  },
};
</script>

<style scoped>
a{
  text-decoration: none;
}
.name-user {
  padding: 0 5px;
  font-size: 13px;
  text-align: center;
}
.name-user span {
  font-style: italic;
}
.btn-avatar i{
  font-size: 24px;
}
/*dropdown*/
/deep/ .dropdown-toggle {
  background-color: transparent;
  border: none;
  width: 38px;
  height: 38px;
  padding: 0;
}

/deep/ .dropdown-toggle:hover, .dropdown-toggle:active, .dropdown-toggle:focus {
  background-color: transparent;
  color: #e7e7e7;
}
/deep/.dropdown-toggle::after {
  display: none;
}
/deep/.dropdown-menu {
  min-width: 10rem;
  padding: 0;
  border: none;
  border-radius: 0px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  padding-bottom: 8px;
}
/deep/ .dropdown-item{
  padding-top: 0;
  padding-bottom: 0;
}
/deep/.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #eeeeee;
  
}
/deep/.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
}
.dropdown-item i{
  color: #656565;
  font-size: 13px;
}
.d-list{
  cursor: pointer;
}
.d-list:hover{
  color: #eeeeee;
}
.list-user {
  color: #656565;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 0.5rem 13px;
  cursor: pointer;
  font-size: 13px;
}
hr {
  margin-bottom: 12px;
  margin-top: 10px;
  background-color: #bfbfbf;
}
.show > .btn-secondary.dropdown-toggle {
  background-color: #025d88 !important;
  color: #ffffff;
}
/*dropdown*/

.btn-orange {
  background-color: #F44802;
  color: #ffffff;
  transition: 0.4s;
}
.btn-orange:hover {
  background-color: #e6784c;
  color: #ffffff;
}
</style>