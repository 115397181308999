<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :back="back" />
    </div>

    <div class="col-12 px-4 pb-5">
      <div class="botones-admins row">
        <div
          class="col-lg col-md-6 col-sm-12 mb-lg-3 mb-3 col-btn"
        >
          <router-link to="/search">
            <div class="button-home">
              <div class="row">
                <div class="col col-svg-max">
                  <i class="fa-solid fa-file"></i>
                </div>
                <div class="col m-auto">
                  <p>Buscar</p>
                  <p class="text-two"> Digesto</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div
          class="col-lg col-md-6 col-sm-12 mb-lg-3 mb-3 col-btn"
        >
          <router-link to="/search-special-document">
            <div class="button-home">
              <div class="row">
                <div class="col col-svg">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="col m-auto">
                  <p>Buscar documento</p>
                  <p class="text-two">de transparencia</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../../public/nav/Nav.vue";

export default {
  props: ["id"],
  data() {
    return {
      back: true,
      submenu: "homeSearch",

    };
  },

  components: {
    Nav,

  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped>
.spinner-orange{
  color: #f44802;
}
.col-btn {
  max-width: 270px;
  min-width: 270px;
}
.button-home {
  background: #f44802;
  color: white;
  padding: 25px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
}
.button-home:hover {
  background-color: #e6784c;
}
.button-home .col-svg {
  max-width: 40px;
  margin: auto;
}
.button-home .col-svg-max {
  max-width: 45px;
  margin: auto;
}
.button-home i {
  font-size: 30px;
}
.button-home p {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}
.button-home .text-two {
  margin-top: -5px;
}
a{
 text-decoration: none;
}

/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}

.th[aria-colindex="6"]{
max-width: 60px;
}
</style>