<template>
  <div>
    <b-modal id="modalChangePass" centered>
      <template #modal-header>
        <h5 class="modal-title" id="modalChangePass">
          {{ name }} {{ lastname }}
        </h5>
      </template>

      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="email"
          disabled
          autocomplete="off"
        />
      </div>
      <div class="mb-3 container-pass">
        <label for="currentPassword" class="form-label"
          >Contraseña actual</label
        >
        <input
          type="text"
          class="form-control"
          id="currentPassword"
          v-model="currentPassword"
          :type="passFieldType"
          autocomplete="off"
        />
        <i class="icon-eye" :class="iconPass" @click="seePass" id="icon"></i>
        <div class="error" v-if="submited && !$v.currentPassword.required">
          Este campo es requerido
        </div>
        <div class="error" v-if="submited && !$v.currentPassword.minLength">
          La contraseña debe ser mas larga
        </div>
      </div>
      <div class="mb-3 container-pass">
        <label for="newPassword" class="form-label">Nueva contraseña</label>
        <input
          type="text"
          class="form-control"
          id="newPassword"
          v-model="newPassword"
          :type="newPassFieldType"
          autocomplete="off"
        />
        <i
          class="icon-eye"
          :class="iconNewPass"
          @click="seeNewPass"
          id="icon"
        ></i>
        <div class="error" v-if="submited && !$v.newPassword.required">
          Este campo es requerido
        </div>
        <div class="error" v-if="submited && !$v.newPassword.minLength">
          La contraseña debe ser mas larga
        </div>
        <div class="error" v-if="submited && !$v.newPassword.maxLength">
          Este campo debe tener menos de 20 caracteres
        </div>
      </div>
      <div class="container-pass">
        <label for="repeatNewPassword" class="form-label"
          >Repetir nueva contraseña</label
        >
        <input
          type="text"
          class="form-control"
          id="repeatNewPassword"
          v-model="repeatPassword"
          :type="repeatPassFieldType"
          autocomplete="off"
        />
        <i
          class="icon-eye"
          :class="iconRepeatNewPass"
          @click="seeRepeatNewPass"
          id="icon"
        ></i>
        <div class="error" v-if="submited && !$v.repeatPassword.sameAsPassword">
          Las contraseñas deben coincidir
        </div>
      </div>

      <template class="text-center" #modal-footer="{ cancel, hide }">
        <!-- Button with custom close trigger value -->
        <b-button
          class="btn-cancel"
          @click="
            () => {
              cancel(),
                currentPassword = '',
                newPassword = '',
                repeatPassword = '';
                 iconPass = 'fas fa-eye',
      iconNewPass = 'fas fa-eye',
      iconRepeatNewPass = 'fas fa-eye'
            }
          "
        >
          Cancelar
        </b-button>
        <b-button
          class="btn-modify"
          @click-self="hide('forget')"
          @click="modify"
        >
          Modificar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { backendUrl } from "../../../config/index";
import axios from "axios";
import store from "../../../store/index";
import {
  required,
  minLength,
  sameAs,
  maxLength,
} from "vuelidate/lib/validators";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      /* user: {}, */
      submited: false,
      name: "",
      lastname: "",
      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
      email: "",

      iconPass: "fas fa-eye",
      iconNewPass: "fas fa-eye",
      iconRepeatNewPass: "fas fa-eye",

      passFieldType: "password",
      newPassFieldType: "password",
      repeatPassFieldType: "password",
    };
  },
  validations: {
    currentPassword: {
      required,
      minLength: minLength(6),
    },
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    repeatPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },
  components: {
    ToastificationContent,
  },
  mounted() {
    this.getDataUser();
  },
  methods: {
    seePass() {
      this.passFieldType =
        this.passFieldType === "password" ? "text" : "password";
      this.iconPass =
        this.iconPass === "fas fa-eye" ? "fas fa-eye-slash" : "fas fa-eye";
    },
    seeNewPass() {
      this.newPassFieldType =
        this.newPassFieldType === "password" ? "text" : "password";
      this.iconNewPass =
        this.iconNewPass === "fas fa-eye" ? "fas fa-eye-slash" : "fas fa-eye";
    },
    seeRepeatNewPass() {
      this.repeatPassFieldType =
        this.repeatPassFieldType === "password" ? "text" : "password";
      this.iconRepeatNewPass =
        this.iconRepeatNewPass === "fas fa-eye"
          ? "fas fa-eye-slash"
          : "fas fa-eye";
    },
    getDataUser() {
      this.token = localStorage.getItem("token");
      this.user = jwt_decode(this.token);
      this.name = this.user.name;
      this.lastname = this.user.lastname;
      this.email = this.user.email;
    },

    async modify() {
      this.submited = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$invalid) {
        this.$store.commit("changeLoading", true);
        const data = {
          email: this.email,
          password: this.currentPassword,
          newPassword: this.newPassword,
        };
        try {
          const response = await axios({
            method: "put",
            url: `${backendUrl}/user/change-pass`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data,
          });
          this.$store.commit("changeLoading", false);
          this.$toast.success(response.data.message, {
            position: "top-right",
            timeout: 2000,
            hideProgressBar: true,
          });
          this.submited = false;
          this.currentPassword = "";
          this.newPassword = "";
          this.repeatPassword = "";
          this.$bvModal.hide("modalChangePass");
        } catch (error) {
          this.$store.commit("changeLoading", false);
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            timeout: 4000,
            hideProgressBar: true,
          });
          /* this.submited = false;
          this.currentPassword = "";
          this.newPassword = "";
          this.repeatPassword = "";
          this.$bvModal.hide("modalChangePass"); */
        }
      }
    },
  },
};
</script>


<style scoped>
.container-pass {
  position: relative;
}
.icon-eye {
  color: #646464;
  font-size: 15px;
  position: absolute;
  right: 11px;
  top: 32px;
  font-size: 14px;
  padding-top: 5px;
  cursor: pointer;
}
.btn-close {
  display: none;
}
/deep/.modal-title {
  color: #646464;
  font-size: 17px;
}
/deep/.modal-header {
  border-bottom: none;
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
}
/deep/.modal-body {
  text-align: left;
  padding-bottom: 8px;
  padding-top: 5px;
  padding-left: 35px;
  padding-right: 35px;
}
/deep/ .modal-body .form-label {
  font-size: 14px;
  margin-bottom: 2px;
}
/deep/ .modal-footer {
  border-top: none;
  padding-top: 0;
  padding-bottom: 25px;
  padding-left: 35px;
  padding-right: 35px;
}
.btn-cancel {
  background-color: #858585;
  color: #ffffff;
  transition: 0.4s;
  border: none;
  font-size: 14px;
}
.btn-cancel:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
.btn-modify {
  background-color: #f44802;
  color: #ffffff;
  transition: 0.4s;
  border: none;
  font-size: 14px;
}
.btn-modify:hover {
  background-color: #e6784c;
  color: #ffffff;
}
</style>