import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.authenticated)) {
		if (localStorage.getItem('token') === null) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath },
			});
		} else {
			next();
		}
	} else if (to.matched.some((record) => record.meta.guest)) {
		if (localStorage.getItem('token') === null) {
			next();
		} else {
			next({ path: '/search-document', });
		}
	} else {
		next();
	}
});


Vue.use(Vuelidate);
Vue.use(Toast);
Vue.use(BootstrapVue)
Vue.config.productionTip = false


Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
