

//URLS DE CONEXION

/* export const backendUrl = 'http://localhost:7020/api';
export const frontEndUrl = 'http://localhost:3000/';   */

export const backendUrl = 'https://digesto-api.jesusmaria.gov.ar/api';
export const frontEndUrl = 'https://digesto.jesusmaria.gov.ar/';

export const preLogin = 'POWEREDBYMOODTECHNOLOGY'
