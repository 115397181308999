export default {

  state:{
      loading:false,
      documentSelect:{},
  },
  getters: {},
  mutations:{
    changeLoading(state, val) {
      state.loading = val;
    },
    saveDocumentSelect(state, value){
      state.documentSelect = value
    },
  },
  getters: {
		authenticated(state) {
			return state.token;
		},
	},
  actions: {} 
}