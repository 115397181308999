<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :back="back"/>
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Datos</h5>
        <p>Ingresar datos para la selección en desplegables del formulario</p>
      </div>
    </div>
    <div class="col-12 mt-4">
      <div class="row">
        <div class="card col-12 p-0 mb-5 shadow-sm">
          <div class="card-body">
            <TypeLegislation />
          </div>
        </div>
        <div class="card col-12 p-0 mb-5 shadow-sm">
          <div class="card-body">
            <TypeArea />
          </div>
        </div>
         <div class="card col-12 p-0 mb-5 shadow-sm">
          <div class="card-body">
             <Keyword/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../../public/nav/Nav.vue";
import TypeLegislation from "./legislation/TypeLegislation.vue";
import TypeArea from "./area/TypeArea.vue";
import Keyword from "./area/keyword/Keyword.vue"

export default {

  data() {
    return {
      
      back: true,
      submenu: "newData",
      name: "",
      lastname: "",
      email: "",
      isAdmin: Boolean,
    };
  },
  components: {
    Nav,
    TypeLegislation,
    TypeArea,
    Keyword
  },
  mounted() {},
  methods: {
  },
};
</script>
<style scoped>
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.form-label {
  font-size: 15px;
  margin-bottom: 2px;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  background: #f44802;
  color: white;
  padding: 9px 35px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}

/*card*/
.card {
  border-radius: 8px;
}
</style>