<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :back="back" />
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Categoría</h5>
        <p>Ingresar datos para la selección en desplegables del formulario</p>
      </div>
    </div>
    <div class="col-12 mt-4">
      <div class="row">
        <div class="card col-12 p-0 mb-5 shadow-sm">
          <div class="card-body">
            <Category :getSpecialCategory="getSpecialCategory"/>
          </div>
        </div>

        <div class="col-12 px-4 mt-4">
          <div class="search row justify-content-end mt-4 pb-2">
            <div class="col-text col-lg col-md-6 mb-lg-0 mb-2">
              <h5>Lista de categorías</h5>
            </div>
            <div
              class="col-search col"
              v-if="categorys.length > 0 && !spinnerTable"
            >
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar"
              ></b-form-input>
            </div>
          </div>
          <b-table
            v-if="categorys.length > 0 && !spinnerTable"
            small
            :fields="fields"
            :items="categorys"
            :per-page="perPage"
            :current-page="currentPage"
            responsive="md"
            :filter="filter"
          >
            <!-- A virtual composite column -->
            <template #cell(option)="data" class="text-end">
              <b-dropdown id="dropdown-right" right class="m-2 btn-avatar">
                <template #button-content class="btn-avatar">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item-button
                  class="d-list"
                  @click="
                    () => {
                      id = data.item._id;
                      $bvModal.show('modalDeleteCategory');
                    }
                  "
                >
                  <label class="list">Eliminar</label>
                </b-dropdown-item-button>
                <!-- <b-dropdown-item-button class="d-list"
            @click="
                () => {
                  nameUser = data.item.name
                  lastnameUser = data.item.name
                  emailUser = data.item.email
                  id = data.item._id;
                  passwordUser = data.item.password
                  $bvModal.show('modalChangePassUser');
                }
              ">
              <label class="list">
                Cambiar contraseña</label
              ></b-dropdown-item-button
            >-->
              </b-dropdown>
            </template>
          </b-table>
          <b-pagination
            v-if="categorys.length > 0 && !spinnerTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
          <div v-if="categorys.length == 0 && !spinnerTable">
            <h6 class="text-center my-4">
              Aún no hay documentos para visualizar
            </h6>
          </div>
          <div class="text-center my-5" v-if="spinnerTable">
            <b-spinner class="spinner-orange" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
      <ModalDeleteCategory :funtionDelete="funtionDelete" />
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Nav from "../../public/nav/Nav.vue";
import Category from "./category/Category.vue";
import ModalDeleteCategory from "./modal/Modal-deleteCategory.vue";
/* import TypeArea from "./area/TypeArea.vue";
import Keyword from "./area/keyword/Keyword.vue" */

export default {
  data() {
    return {
      back: true,
      submenu: "newCategory",
      user: {},
      name: "",
      lastname: "",
      email: "",
      isAdmin: Boolean,

      id: 0,

      /*table*/
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "name",
          label: "Nombre",
          filterByFormatted: true,
          sortable: true,
          class: "text-mayus" 
        },
        { key: "option", label: "", class: "text-end with-th" },
      ],
      filter: null,
      categorys: [],
      spinnerTable: false,
      id: 0,
    };
  },
  components: {
    Nav,
    Category,
    ModalDeleteCategory,
    /*     TypeArea,
    Keyword */
  },
  mounted() {
    this.getSpecialCategory();
  },
  methods: {
    getDataUser() {
      this.token = localStorage.getItem("token");
      this.user = jwt_decode(this.token);
    },
    async getSpecialCategory() {
      this.spinnerTable = true;
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/special-category/get`,
        });
        if (response.status == 200) {
          this.categorys = response.data.specialCategoriesGetted;
        }
        this.spinnerTable = false;
      } catch (error) {
        this.spinnerTable = false;
      }
    },
    funtionDelete() {
      this.deleteCategory(this.id);
    },
    async deleteCategory(id) {
      this.$store.commit("changeLoading", true);
      try {
        const response = await axios({
          method: "delete",
          url: `${backendUrl}/special-category/delete/${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.getSpecialCategory();
        this.$store.commit("changeLoading", false);
        this.$toast.success(response.data.message, {
          position: "top-right",
          timeout: 2000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteCategory");
      } catch (error) {
        this.$store.commit("changeLoading", false);
        this.$toast.error(error.response.data.message, {
          position: "top-right",
          timeout: 4000,
          hideProgressBar: true,
        });
        this.$bvModal.hide("modalDeleteCategory");
      }
    },
  },
  computed: {
    rows() {
      return this.categorys.length;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
};
</script>
<style scoped>
/deep/.with-th {
  max-width: 3%;
}
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.form-label {
  font-size: 15px;
  margin-bottom: 2px;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  background: #f44802;
  color: white;
  padding: 9px 35px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}

/*card*/
.card {
  border-radius: 8px;
}

/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}

.th[aria-colindex="6"] {
  max-width: 60px;
}

/*dropdown*/
/deep/ .dropdown-toggle {
  background-color: transparent !important;
  border: none;
  width: 38px;
  height: 24px;
  padding: 0;
  color: black;
  transition: 0.4s;
}
/deep/ .dropdown-toggle:hover,
.dropdown-toggle:active {
  background-color: transparent !important;
  color: #8b8b8b;
}
/deep/.dropdown-toggle::after {
  display: none;
}
/deep/.dropdown-menu {
  min-width: 10rem;
  margin-top: 0px;
  border: none;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
/deep/.dropdown-item {
  padding-top: 0px;
  color: #656565;
  transition: 0.4s;
  padding-left: 5px;
  padding-right: 5px;
}
/deep/.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #f5f5f5;
}
.d-list {
  cursor: pointer;
}
.list {
  color: #656565;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 0.5rem 13px;
  cursor: pointer;
  font-size: 13px;
}

/deep/.show > .btn-secondary.dropdown-toggle {
  background-color: #ffffff !important;
  color: #000000;
}
/*dropdown*/
/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}
</style>