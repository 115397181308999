var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-12 mt-5"},[_c('Nav',{attrs:{"submenu":_vm.submenu,"backSearch":_vm.backSearch}})],1),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"titles"},[_c('h5',[_vm._v("Documento")]),_c('p',[_vm._v("Se muestran también los datos de carga del mismo")]),_vm._l((_vm.detalle.palabrasClave),function(palabra){return _c('span',{key:palabra},[_c('span',{staticClass:"badge rounded-pill bg-orange"},[_vm._v(_vm._s(palabra))])])})],2)]),_c('div',{staticClass:"col-12 px-4 pb-4 mt-2"},[_c('div',{staticClass:"row"},[_vm._l((Object.entries(_vm.detalle.fields)),function(detalle,index){return _c('div',{key:index,staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v(_vm._s(detalle[0]))]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"name","placeholder":"Ingrese un dato","autocomplete":"off","disabled":""},domProps:{"value":detalle[1]}})])])}),_c('div',{staticClass:"col-12 mt-4"},[_c('div',{staticClass:"search row justify-content-end mb-3"},[_vm._m(0),_c('div',{staticClass:"col-md-10 col-12 m-auto"},[_c('p',{staticClass:"nameDocument"},[_vm._v(_vm._s(_vm.detalle.fileName))])]),_c('div',{staticClass:"col-md-2 col-12 text-end d-md-block d-none"},[(_vm.detalle.isPDF)?_c('a',{staticClass:"btn btn-document btn-sm",attrs:{"href":'http://' + _vm.detalle.savePath,"target":"_black"}},[_vm._v("Ver archivo")]):_vm._e(),(!_vm.detalle.isPDF)?_c('a',{staticClass:"btn btn-document btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.downloadItem({
                  url: 'https://' + _vm.detalle.savePath,
                  label: 'example',
                  fileName: _vm.detalle.fileName,
                })}}},[_vm._v(" Descargar archivo ")]):_vm._e()]),_c('div',{staticClass:"col-md-2 col-12 text-end d-md-none d-block"},[(_vm.detalle.isPDF)?_c('a',{staticClass:"btn btn-document btn-sm",attrs:{"href":'http://' + _vm.detalle.savePath},on:{"click":function($event){$event.preventDefault();return _vm.downloadItem({
                  url: 'https://' + _vm.detalle.savePath,
                  label: 'example',
                  fileName: _vm.detalle.fileName,
                })}}},[_vm._v("Descargar archivo")]):_vm._e(),(!_vm.detalle.isPDF)?_c('a',{staticClass:"btn btn-document btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.downloadItem({
                  url: 'https://' + _vm.detalle.savePath,
                  label: 'example',
                  fileName: _vm.detalle.fileName,
                })}}},[_vm._v(" Descargar archivo ")]):_vm._e()]),_c('hr')])])],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',[_vm._v("Contenido")]),_c('hr')])}]

export { render, staticRenderFns }