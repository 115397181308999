<template>
  <b-modal id="modalKeyword" centered>
    <div class="">
      <h5 class="mb-3">Agregar una nueva palabra clave</h5>
      <NewKeyword />
    </div>

    <template class="text-end" #modal-footer="{ cancel }">
      <!-- Button with custom close trigger value -->
      <b-button
        class="btn-cancel"
        @click="
          () => {
            getArea();
            cancel();
          }
        "
      >
        Cerrar
      </b-button>
      <!--           <b-button
            class="btn-orange"
            @click-self="hide('forget')"
            @click="save"
          >
            Guardar
          </b-button> -->
    </template>
  </b-modal>
</template>
<script>
import NewKeyword from "../keyword/Keyword.vue";
export default {
  props: ["getArea"],
  components: {
    NewKeyword,
  },
};
</script>

<style scoped>
/deep/.close {
  display: none;
}
/deep/ .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
/deep/ .modal-body {
  padding-bottom: 15px;
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
}
/deep/ .modal-footer {
  border-top: none;
  padding-top: 0px;
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 10px;
}
.btn-cancel {
  background-color: #858585;
  color: #ffffff;
  border: none;
  transition: 0.4s;
  font-size: 14px;
}
.btn-cancel:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}
.btn-orange {
  background-color: #f44802;
  color: #ffffff;
  border: none;
  transition: 0.4s;
  font-size: 14px;
}
.btn-orange:hover {
  background-color: #e6784c;
  color: #ffffff;
}
</style>
