<template>
  <div>
    <div class="col-12 mt-5">
      <Nav :submenu="submenu" :backSearch="backSearch" />
    </div>

    <div class="col-12">
      <div class="titles">
        <h5>Búsqueda de documentos</h5>
        <p>Ingresa los datos para buscar un documento de transparencia</p>
      </div>
    </div>
    <div class="col-12 px-4 pb-4 mt-2">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="" class="form-label">Categoría</label>
            <Multiselect
              id="keyword"
              v-model="typeCategory"
              :options="categorys"
              track-by="name"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Selecione una categoría"
              label="name"
              :preselect-first="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
            >
              <span slot="noOptions"> Aún no hay tipo de categoría</span>
              <span slot="noResult">Oops! No se encontraron elementos.</span>

            </Multiselect>
          </div>
        </div>

         <div
          class="col-md-6 col-12"
          v-for="(category, index) in typeCategory.fields"
          :key="index"
        >
          <div class="mb-3">
            <label for="name" class="form-label">{{
              category.nameField
            }}</label>
            <input
              v-model="category.value"
              :type="category.type"
              class="form-control"
              id="name"
              placeholder="Ingrese un dato"
              autocomplete="off"
            />
          </div>
        </div>
   



        <div class="col-12 text-end">
          <button class="btn btn-create btn-sm" @click="search(typeCategory._id)" :disabled="!typeCategory.fields">Buscar</button> 
        </div>
      </div>
    </div>
    <div class="col-12 px-4 mt-4">
      <div class="search row justify-content-end mt-4 pb-2">
        <div class="col-search col" v-if="showTable">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Buscar"
          ></b-form-input>
        </div>
      </div>
      <b-table
        v-if="showTable"
        small
        :fields="fields"
        :items="documents"
        :per-page="perPage"
        :current-page="currentPage"
        responsive="md"
        :filter="filter"
      >
        <!-- A virtual composite column -->
        <template #cell(view)="data">
          <div class="text-end px-2">
            <button
              class="btn"
              @click="
                () => {
                  details = data.item;
                  seeDocumento(details);
                }
              "
            >
              <i class="fa-solid fa-eye"></i>
            </button>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="showTable"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <div v-if="showTable == false">
        <h6 class="text-center my-4">
          No se encontraron archivos en esta búsqueda
        </h6>
      </div>
    </div>
  </div>
</template>
<script>
import { backendUrl } from "../../../config/index";
import axios from "axios";
import store from "../../../store/index";
import Nav from "../nav/Nav.vue";
import { useToast } from "vue-toastification";
import Multiselect from "vue-multiselect";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import ToastificationContent from "../../../components/toastification/ToastificationContent.vue";
/* import ModalConfirmDocument from './modal/ModalConfirmDocument.vue' */
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      backSearch: true,
      submenu: "searchSpecialDocument",
      typeCategory: "",
      categorys: [],




      palabra: [],

      showTable: null,
      details: [],
      /*table*/
      perPage: 5,
      currentPage: 1,
      fields: [
        {
          key: "category",
          label: "Categoría",
          filterByFormatted: true,
          sortable: true,
          class: "text-mayus" 
        },
        {
          key: "title",
          label: "Tìtulo",
          filterByFormatted: true,
          sortable: true,
          class: "text-mayus" 
        },

        { key: "view", label: "" },
      ],
      documents: [],
      filter: null,
    };
  },
  components: {
    Nav,

    /* ModalConfirmDocument */
    Multiselect,
    ToastificationContent,
  },
  mounted() {
    this.getLegisletion();
    this.getArea();
    this.getSpecialcCategory();
  },
  methods: {
    async getSpecialcCategory() {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/special-category/get`,
        });
        if (response.status == 200) {
          this.categorys = response.data.specialCategoriesGetted;
        }
      } catch (error) {
      }
    },

     async search(id) {
       this.showTable = true;
       this.$store.commit("changeLoading", true);
       const data = this.typeCategory.fields.reduce((filterAcum, field) => {
         filterAcum[field.nameField] = field.value;       
         return filterAcum;
         }, {})

      try {
        const response = await axios({
          method: "post",
          url: `${backendUrl}/special-document/get/${id}`,
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          params: {...data}
        });
        if (response.status == 200) {
          this.showTable = true;
          this.documents = response.data.documentsGetted;
        }
        this.$store.commit("changeLoading", false);
        this.$toast.success(response.data.message, {
          position: "top-right",
          timeout: 2000,
          hideProgressBar: true,
        });
      } catch (error) {
        this.showTable = false;
        this.$store.commit("changeLoading", false);
        this.$toast.error(error.response.data.message, {
          position: "top-right",
          timeout: 4000,
          hideProgressBar: true,
        });
      }
    }, 
    async downloadItem({ url, label, fileName }) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.setAttribute("download", fileName);
      link.click();
      URL.revokeObjectURL(link.href);
    },
    seeDocumento(details) {
      this.$store.commit("saveDocumentSelect", details);
      this.$router.push({ path: "/special-document-detail" });
    },

    async getLegisletion() {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/legislation/get-legislations`,
        });
        if (response.status == 200) {
          this.legislations = response.data.legislationsGetted;
        }
      } catch (error) {}
    },
    async getArea() {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/area/get-areas`,
        });
        if (response.status == 200) {
          this.areas = response.data.areasGetted;
        }
      } catch (error) {}
    },
  },
  computed: {
    rows() {
      return this.documents.length;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
};
</script>
<style scoped>
.titles h5 {
  font-weight: 700;
  font-size: 26px;
  color: #707070;
  margin-bottom: 0px;
}
.titles p {
  font-weight: 400;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0;
}
.form-label {
  font-size: 14px;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.form-check-input:checked {
  background-color: #f44802;
  border-color: #f44802;
}
.btn-create {
  background: #f44802;
  color: white;
  padding: 9px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.btn-create:hover {
  background-color: #e6784c;
  color: white;
}

/*table*/
.search {
  padding: 0 12px;
}
.col-text {
  padding-left: 0;
  margin: auto;
}
.col-text h5 {
  margin-bottom: 0;
  font-weight: 600;
}
.col-search {
  max-width: 250px;
  padding-right: 0;
}
.col-search input {
  font-size: 13px;
}
.col-filter {
  max-width: 42px;
  padding: 0px;
}
/deep/.table thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
/deep/.table > :not(:first-child) {
  border-top: none;
}
/deep/.table thead th {
  font-weight: 700;
}
/deep/.table tbody tr td {
  vertical-align: middle;
}
/deep/.table tbody tr .td-icon {
  width: 5%;
}

/*dropdown*/
/deep/ .dropdown-toggle {
  background-color: transparent !important;
  border: none;
  width: 38px;
  height: 24px;
  padding: 0;
  color: black;
  transition: 0.4s;
}
/deep/ .dropdown-toggle:hover,
.dropdown-toggle:active {
  background-color: transparent !important;
  color: #8b8b8b;
}
/deep/.dropdown-toggle::after {
  display: none;
}
/deep/.dropdown-menu {
  min-width: 10rem;
  margin-top: 0px;
  border: none;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
/deep/.dropdown-item {
  padding-top: 0px;
  color: #656565;
  transition: 0.4s;
  padding-left: 5px;
  padding-right: 5px;
}
/deep/.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #f5f5f5;
}
.d-list {
  cursor: pointer;
}
.list {
  color: #656565;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
  padding: 0.5rem 13px;
  cursor: pointer;
  font-size: 13px;
}

/deep/.show > .btn-secondary.dropdown-toggle {
  background-color: #ffffff !important;
  color: #000000;
}
/*dropdown*/
/*paginacion*/
/deep/ .pagination {
  /* font-family: maven-bold; */
  font-size: 13px;
  float: right;
}
/deep/ .page-link {
  color: black;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}
/deep/ .page-link:hover {
  color: black;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
/deep/ .page-item.active .page-link {
  background-color: #ebebeb75;
  border-color: #ebebeb75;
  color: black;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
}
.r-pagination {
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
}
</style>