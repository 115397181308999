var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 px-4 mt-4 py-5"},[_c('div',{staticClass:"search row justify-content-end mt-5 pb-2"},[_vm._m(0),(_vm.documents.length > 0 && !_vm.spinnerTable)?_c('div',{staticClass:"col-search col"},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Buscar"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1):_vm._e()]),(_vm.documents.length > 0 && !_vm.spinnerTable)?_c('b-table',{attrs:{"small":"","fields":_vm.fields,"items":_vm.documents,"per-page":_vm.perPage,"current-page":_vm.currentPage,"responsive":"md","filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(view)",fn:function(data){return [_c('div',{staticClass:"text-end px-2",staticStyle:{"max-width":"60px","margin":"auto"}},[_c('button',{staticClass:"btn",on:{"click":function () {
              _vm.details = data.item;
              _vm.seeDocumento(_vm.details);
            }}},[_c('i',{staticClass:"fa-solid fa-eye"})])])]}},(this.user.isAdmin)?{key:"cell(delete)",fn:function(data){return [_c('div',{staticClass:"text-end px-2",staticStyle:{"max-width":"60px","margin":"auto"}},[_c('button',{staticClass:"btn btn-sm btn-trash",on:{"click":function () {
              _vm.id = data.item._id;
              
              _vm.$bvModal.show('modalDeleteSpecialDocument');
            }}},[_c('i',{staticClass:"fa-solid fa-trash-can"})])])]}}:null],null,true)}):_vm._e(),(_vm.documents.length > 0 && !_vm.spinnerTable)?_c('b-pagination',{staticClass:"mb-5",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.documents.length == 0 && !_vm.spinnerTable)?_c('div',[_c('h6',{staticClass:"text-center my-4"},[_vm._v("Aún no hay documentos para visualizar")])]):_vm._e(),(_vm.spinnerTable)?_c('div',{staticClass:"text-center my-5"},[_c('b-spinner',{staticClass:"spinner-orange",attrs:{"label":"Spinning"}})],1):_vm._e(),_c('ModalDeleteSpecialDocument',{attrs:{"funtionDelete":_vm.funtionDelete}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-text col-lg col-md-6 mb-lg-0 mb-2"},[_c('h5',[_vm._v("Documento de Transparencia")])])}]

export { render, staticRenderFns }